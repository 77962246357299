import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/WebGLTile';
import OSM from 'ol/source/OSM';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Overlay } from 'ol';
import { fromLonLat, toLonLat } from 'ol/proj.js';
import { toStringHDMS } from 'ol/coordinate.js';
import Draw from 'ol/interaction/Draw.js';
import { FullScreen, defaults as defaultControls } from 'ol/control.js';
import Graticule from 'ol/layer/Graticule.js';
// import Link from 'ol/interaction/Link.js';
import { Icon, Style, Text, Fill, Stroke } from 'ol/style';

import { Box, Typography, Chip, Card, CardMedia, Alert, Divider, Stack, ListItem, ListItemText, Avatar, ListItemAvatar, Accordion, AccordionSummary, AccordionDetails, Link, IconButton, Fab, Tooltip } from '@mui/material';

import ShowAddress from './ShowAddress';

import { pb } from '../utilities/pocketbase';
import { format, formatDistanceToNow } from 'date-fns';
import { formatBytes } from '../utilities/common';

import { useTheme } from '@mui/material/styles';
import { AppContextProvider } from '../AppContext';
import CustomThemeProvider from '../CustomTheme';

import DevicesIcon from '@mui/icons-material/Devices';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PlaceIcon from '@mui/icons-material/Place';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';

import { BsWindows, BsApple } from 'react-icons/bs'
import { FcLinux } from "react-icons/fc";
import { ImAndroid } from "react-icons/im";

const MachineLocationMap = ({ id = "MachineLocationMap", maxHeight, startLocation = [78.9629, 20.5937], matchDid = "" }) => {
    const theme = useTheme()
    const [machines, setMachines] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [showAddress, setShowAddress] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [refresh, setRefresh] = useState(true)
    const [markerData, setMarkerData] = useState([]);
    const [animateTo, setAnimateTo] = useState([78.9629, 20.5937])
    const [view, setView] = useState()

    // Ref for the vector layer that contains the markers 
    const vectorLayerRef = useRef();

    let draw; // global so we can remove it later 
    function addInteraction(map, drawSource, type = "Circle") {
        const value = type;
        if (value !== 'None') {
            draw = new Draw({
                source: drawSource,
                type: type,
                freehand: true,
            });
            map.addInteraction(draw);
        }
    }

    useEffect(() => {
        if (id) {
            console.log(`🗺️ ${id} Map Draw`)
            // Create a vector layer for the markers 
            const vectorSource = new VectorSource();
            const vectorLayer = new VectorLayer({
                source: vectorSource,
            });
            vectorLayerRef.current = vectorLayer;

            const drawSource = new VectorSource({ wrapX: false });
            const drawVector = new VectorLayer({
                source: drawSource,
            });

            const view = new View({
                center: fromLonLat([78.9629, 20.5937]), // [lat, lon]
                zoom: 3,
            })

            setView(view)

            const graticuleLines = new Graticule({
                // the style to use for the lines, optional. 
                strokeStyle: new Stroke({
                    color: '#bdbdbd',
                    width: 1,
                    lineDash: [0.5, 4],
                }),
                showLabels: false,
                wrapX: false,
            })

            // Create a map with the OpenLayers library 
            const map = new Map({
                controls: defaultControls().extend([new FullScreen()]),
                target: id,
                layers: [
                    new TileLayer({
                        source: new OSM(),
                    }),
                    vectorLayer,
                    drawVector,
                    // graticuleLines,
                ],
                view: view
            });

            // map.addInteraction(new Link());

            // dark mode map 
            // map.on('postcompose', function (e) {
            //     document.querySelector('canvas').style.filter = ({ dark: "grayscale(80%) invert(100%) ", light: "" }[theme.palette.mode]);
            // });

            // addInteraction(map, drawSource, ["LineString", "Polygon", "Circle", "None"][3])

            // Add a mouseover (pointermove) event listener to the map 
            map.on('click' || 'pointermove', (event) => {
                const feature = map.forEachFeatureAtPixel(event.pixel, (feature) => feature);
                if (feature && feature.get('overlay')) {
                    // console.log(feature?.id_) 
                    const overlay = feature.get('overlay');
                    overlay.setPosition(event.coordinate);
                    map.addOverlay(overlay)
                } else {
                    const overlays = map.getOverlays().getArray();
                    overlays.forEach((overlay) => { overlay.setPosition(undefined); map.removeOverlay(overlay) });
                }
            });

            // map.on("dblclick", (event) => { 
            //     map.removeInteraction(draw) 
            // }) 

            // map.on('click', function (evt) {
            //     const coordinate = evt.coordinate;
            //     const hdms = toStringHDMS(toLonLat(coordinate));
            //     console.log(hdms)
            //     console.table(coordinate);

            // });

            return () => {
                // Clean up the map when the component unmounts 
                map.setTarget(null);
            };
        }
    }, [id]);

    useEffect(() => {
        if (pb?.authStore?.model?.id && refresh && id) {
            setLoading(true)
            pb.collection('machine_list')
                // .getList(1, 50).then(deviceList => {
                .getFullList().then(machineList => {
                    // console.log("machineList: ", machineList)
                    setMachines(machineList)
                    let locations = machineList.map(machine => ({
                        id: machine?.machineId,
                        machine: machine?.id,
                        lat: machine?.staticInfo?.ipInfo?.loc?.split(",")?.[0],
                        lng: machine?.staticInfo?.ipInfo?.loc.split(",")?.[1],
                        staticInfo: machine?.staticInfo,
                        cpu: machine?.staticInfo?.cpu,
                        system: machine?.staticInfo?.system,
                        os: machine?.staticInfo?.os
                    }))

                    // console.log("locations:", locations)
                    setMarkerData(locations)
                    setLoading(false)
                    setRefresh(false)
                }).catch(error => {
                    console.log("device list error: ", error)
                    setLoading(false)
                    setRefresh(false)
                })
        }
    }, [refresh, id])

    // useEffect(() => {
    //     if (view && animateTo && animateTo.length === 2) {
    //         setTimeout(() => {
    //             view.animate({
    //                 center: animateTo,
    //                 duration: 2000,
    //             });
    //         }, 2000);
    //     }
    // }, [view, animateTo])

    useEffect(() => {
        // Update the vector layer with the new marker data 
        if (vectorLayerRef.current && markerData.length) {
            const markers = markerData
                // .filter(x => (x?.id === matchDid || x?.id === appState?.did) && x)
                .map((marker) => {
                    const { id, lat, lng } = marker;
                    const markerFeature = new Feature({
                        geometry: new Point(fromLonLat([lng, lat])),
                    });

                    markerFeature.setStyle(new Style({
                        image: new Icon({
                            crossOrigin: 'anonymous',
                            src: 'pos_anonymous.svg',
                            scale: 0.025,
                        })
                    }))

                    // Create an overlay for the tooltip 
                    const overlay = new Overlay({
                        element: document.createElement('div'),
                        offset: [5, 0],
                        positioning: 'bottom-left',
                    });
                    overlay.getElement().textContent = id;
                    overlay.getElement().innerHTML = "";

                    // ReactDOM.render(tooltipContent, overlay.getElement());
                    ReactDOM.createRoot(overlay.getElement()).render(<TooltipContent key={id} id={id} marker={marker} />);
                    markerFeature.set('overlay', overlay);

                    markerFeature.setId(id);
                    return markerFeature;
                });

            const vectorSource = vectorLayerRef.current.getSource();
            vectorSource.clear();
            vectorSource.addFeatures(markers);
        }
    }, [markerData, matchDid]);

    return (
        <Box sx={{ position: "relative", p: 0, height: maxHeight ? maxHeight : `${window.innerHeight}px` }}>
            <CardMedia component={"div"} id={id} style={{ width: '100%', height: maxHeight ? maxHeight : `${window.innerHeight}px` }} />
            <Box sx={{ position: "absolute", bottom: 0, right: 0, p: 1 }}>
                <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"}>
                    {/* <Tooltip title={"Layers"}>
                        <Fab size='medium'>
                            <LayersOutlinedIcon />
                        </Fab>
                    </Tooltip> */}
                </Stack>
            </Box>
        </Box>
    );
};

const TooltipContent = ({ id, marker }) => {

    return (
        <AppContextProvider>
            <CustomThemeProvider>
                <Card id={id} variant='outlined' sx={{ borderRadius: 3, maxWidth: 500, wordBreak: "break-all" }}>
                    <ListItem variant="outlined" key={id}>
                        <ListItemAvatar>
                            <Avatar src={""} variant="rounded" sx={{ background: "transparent" }}>
                                <DevicesIcon color={"secondary"} />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary={id} />
                    </ListItem>
                    <Divider />
                    {marker?.staticInfo &&
                        <Accordion sx={{ boxShadow: 0 }}>
                            <AccordionSummary
                                expandIcon={<ArrowDropDownIcon />}
                            >
                                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                    {marker?.staticInfo?.os?.platform && marker?.staticInfo?.os?.platform.toLowerCase() === 'windows' && <BsWindows size={"1rem"} color="#0078D6" />}
                                    {marker?.staticInfo?.os?.platform && marker?.staticInfo?.os?.platform.toLowerCase() === 'android' && <ImAndroid size={"1rem"} color='#3DDC84' />}
                                    {marker?.staticInfo?.os?.platform && marker?.staticInfo?.os?.platform.toLowerCase() === 'linux' && <FcLinux size={"1rem"} color='#3DDC84' />}
                                    {marker?.staticInfo?.os?.platform && ["macos", "ios", "ipados", "visionos", 'osx'].includes(marker?.staticInfo?.os?.platform + "".toLowerCase()) && <BsApple size={"1rem"} color="#6C6C6C" />}
                                    <Typography variant='subtitle2'>Machine Info {marker?.staticInfo?.os?.platform && `• ${marker?.staticInfo?.os?.platform}`}</Typography>
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack>
                                    {marker?.machine && <Stack key={"machine"} direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"center"}>
                                        <Typography variant='caption' color={"text.secondary"}>{"machine"}</Typography>
                                        <Typography component={Link} color={"primary.main"} href={`/Machines?id=${marker?.machine}`} fontFamily={"JetBrains Mono"} variant='subtitle2'>{"" + marker?.machine}</Typography>
                                    </Stack>}

                                    <Stack key={"memory"} direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"center"}>
                                        <Typography variant='caption' color={"text.secondary"}>{"memory"}</Typography>
                                        <Typography variant='subtitle2'>{marker?.staticInfo?.memLayout.map(x => (
                                            formatBytes(x?.size)
                                        )).join(", ")}</Typography>
                                    </Stack>

                                    <Stack key={"cpu"} direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"center"}>
                                        <Typography variant='caption' color={"text.secondary"}>{"CPU"}</Typography>
                                        <Typography variant='subtitle2'>{`${marker?.staticInfo?.cpu?.brand} • ${marker?.staticInfo?.cpu?.cores} cores`}</Typography>
                                    </Stack>

                                    <Stack key={"system"} direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"center"}>
                                        <Typography variant='caption' color={"text.secondary"}>{"system"}</Typography>
                                        <Typography variant='subtitle2'>{`${marker?.staticInfo?.system?.manufacturer} • ${marker?.staticInfo?.system?.model}`}</Typography>
                                    </Stack>

                                    {Object.keys(marker?.staticInfo?.os).filter(key => ["arch", "platform", "release", "hostname", "distro", "kernel"].includes(key) && key).map(key => (
                                        <Stack key={key} direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography variant='caption' color={"text.secondary"}>{key}</Typography>
                                            <Typography variant='subtitle2'>{"" + marker?.staticInfo?.os?.[key]}</Typography>
                                        </Stack>
                                    ))}
                                    <Stack justifyContent={"center"} alignItems={"center"} sx={{ mt: 1 }}>
                                        <ShowAddress location={{ latitude: marker?.lat, longitude: marker?.lng }} tiny={false} color={"secondary"} />
                                    </Stack>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>}
                    {marker?.staticInfo?.os && Object.keys(marker?.staticInfo?.os).length > 0 && <Divider />}
                    <Stack sx={{ p: 1 }}>
                        <Typography align='center' variant="subtitle2" fontWeight={"light"} color={"text.secondary"}>Latitude: {marker?.lat}, Longitude: {marker?.lng}</Typography>
                        {marker?.timestamp && <Typography align="center" color={"text.secondary"} variant="subtitle2" fontWeight={"light"}>Updated at: {marker?.timestamp && format(new Date(marker?.timestamp), "Ppp")} {`${marker?.timestamp && "(" + formatDistanceToNow(new Date(marker?.timestamp)) + " ago)"}`}</Typography>}
                    </Stack>
                </Card>
            </CustomThemeProvider>
        </AppContextProvider >
    )
}

export default MachineLocationMap;
