import { useState, useEffect } from "react";
import { Alert, Box, Chip, CircularProgress, Stack, IconButton, Tooltip, Typography } from '@mui/material';

import axios from "axios";

import PlaceIcon from '@mui/icons-material/Place';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export default function ShowAddress({ location, label = "", tiny = true, props = {}, color }) {
    const [address, setAddress] = useState({ data: "", loading: false, error: "" })
    const [showAddress, setShowAddress] = useState(false)
    const [hideAddress, setHideAddress] = useState(true)

    useEffect(() => {
        if (address.data === "" && location?.latitude && location?.longitude && showAddress && process.env.REACT_APP_GEOCODE_API_KEY) {
            setAddress(ps => ({ ...ps, loading: true, error: "" }))
            axios.get(`https://geocode.maps.co/reverse?lat=${location?.latitude}&lon=${location?.longitude}&api_key=${process.env.REACT_APP_GEOCODE_API_KEY}`).then(response => {
                setAddress(ps => ({ ...ps, data: response?.data?.display_name, loading: false, error: "" }))
                setShowAddress(false)
            }).catch(error => {
                console.log("Geocode error: ", error)
                setAddress(ps => ({ ...ps, error: error?.message, loading: false }))
                setShowAddress(false)
            }).finally(() => {
                setShowAddress(false)
                setAddress(ps => ({ ...ps, loading: false }))
            })
        }
    }, [location, showAddress, address.data])

    return (
        <Box maxWidth={'sm'}>
            {(address.data && !hideAddress) ? <Tooltip title={`${address.data}`}> <Alert severity={color ? color : 'primary'} icon={false} sx={{ py: 0, px: 1, border: 2, borderRadius: 3, opacity: .8, wordBreak: "break-all", alignItems: "center", ...props }}>
                <Stack direction={"row"} spacing={1} justifyContent={"center"} alignItems={"flex-start"}>
                    <PlaceIcon color={color ? color : 'inherit'} />
                    <Typography variant="subtitle2">
                        {`${label} Address: `} {tiny ? [address.data.split(",").slice(0, 2), address.data.split(",").slice(-3)].join(", ") : address.data}
                    </Typography>
                    <IconButton sx={{ ml: 1 }} size="small" onClick={() => setHideAddress(true)}>
                        <CloseRoundedIcon fontSize=".5rem" />
                    </IconButton>
                </Stack>
            </Alert></Tooltip> : <Chip label={`Show ${label} Address`} icon={address?.loading && <CircularProgress size={"1.25rem"} thickness={6} />} sx={{ borderRadius: 5, border: 2 }} onClick={() => { setShowAddress(true); setHideAddress(false) }} />}
        </Box>
    )
}