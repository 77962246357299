import * as React from 'react';
import { AppBar, Box, Stack, Toolbar, Tooltip, Avatar, Collapse, Button } from '@mui/material';
import { IconButton, Typography, CardMedia, LinearProgress, Menu, MenuItem, Divider } from '@mui/material';
import { Link as HyperLink } from '@mui/material';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import AppContext from '../AppContext';
import { AuthRequiredComponent } from './Auth'
import { pb } from '../utilities/pocketbase';

import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';

import { ReactComponent as KunctIcon } from '../knuctIcon.svg'

import { ShowDateTime } from './ShowDateTime';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

export default function NavBar({ type = "menu" }) {
    const theme = useTheme()
    const [appState, appStateDispatch] = React.useContext(AppContext)
    const [openSearch, setOpenSearch] = React.useState(false)
    const [openDrawer, setOpenDrawer] = React.useState(false)
    const [currentTime, setCurrentTime] = React.useState(new Date())
    const xsScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));

    const navigate = useNavigate()

    const toggleThemeClick = () => {
        appStateDispatch({ type: 'toggleTheme' })
        // closeSettingsMenu()
    }

    React.useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date())
        }, 1000);

        return () => clearInterval(timer)
    }, [])

    return (
        <Box>
            <AppBar position="relative" color='transparent' className="navbar" sx={{ boxShadow: 0, backdropFilter: "blur(20px)", borderBottom: 1, borderColor: "divider" }}>
                <Toolbar sx={{ ...(false && { borderBottom: 1, borderColor: "text.disabled" }) }}>
                    <MainLogo />
                    
                    <AuthRequiredComponent>
                        {!smScreen && <DesktopNavMenu />}
                    </AuthRequiredComponent>

                    <Box sx={{ flexGrow: 1 }} />

                    <Stack direction={"row"} spacing={0.5} alignItems={"center"} justifyContent={"flex-end"}>
                        <AuthRequiredComponent>
                            {pb?.authStore?.model?.id && <Button onClick={() => { pb.authStore.clear(); navigate("/") }}
                                sx={{ color: "text.secondary", mr: { xs: 0, md: 2 }, textTransform: 'none', borderRadius: 5, px: 2 }} >
                                Logout
                            </Button>}
                        </AuthRequiredComponent>
                        {/* {pb.authStore.model.id && <Button sx={{ borderRadius: 5, px: 2, textTransform: "none" }} color='text.secondary' onClick={() => pb.authStore.clear()}>Logout</Button>} */}
                        <Tooltip title="theme"><IconButton color={({ 'dark': undefined, 'light': "warning" }[theme.palette.mode])} onClick={toggleThemeClick}>{({ 'dark': <DarkModeOutlinedIcon />, 'light': <LightModeOutlinedIcon /> }[theme.palette.mode])}</IconButton></Tooltip>
                        {
                            smScreen &&
                            <IconButton onClick={() => { setOpenDrawer(!openDrawer) }}>
                                {openDrawer ? <CloseIcon /> : <MenuIcon />}
                            </IconButton>
                        }
                        {!xsScreen && <ShowDateTime currentTime={currentTime} />}
                    </Stack>
                </Toolbar>
                {smScreen && <MobileNavMenu open={openDrawer} onClose={() => { setOpenDrawer(false) }} />}
            </AppBar>
        </Box>
    )
}

const logoAnimationStyle = {
    background: "linear-gradient(-45deg, #00F5FF, #FCE700, #FF6D28, #EA047E)",
    backgroundSize: "200% 200%",
    // WebkitBackgroundClip: "text",
    // WebkitTextFillColor: "transparent",
    animation: "gradient 30s ease infinite",
    "@keyframes gradient": {
        "0%": {
            backgroundPosition: "0% 50%",
        },
        "50%": {
            backgroundPosition: "100% 50%"
        },
        "100%": {
            backgroundPosition: "0% 50%"
        }
    }
}

function MainLogo() {
    // console.log("LOGO-LOADED!")

    return (
        <Stack>
            <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} color={"inherit"} sx={{ textDecoration: "none" }}
                component={Link} to="/"
            >
                <CardMedia sx={{ mr: 1 }}>
                    <KunctIcon style={{ width: '2em', height: '2em', verticalAlign: 'center' }} />
                </CardMedia>
                <Stack spacing={-1} justifyContent={"center"} alignItems={"flex-start"} direction={"column"}>
                    <Typography variant='caption' fontFamily={"JetBrains Mono"} sx={{ fontWeight: "bold" }}>KNUCT</Typography>
                    <Typography variant='subtitle1' fontFamily={"JetBrains Mono"} sx={{ fontWeight: "bold" }}>DISCOVER</Typography>
                </Stack>
            </Stack>
            <LinearProgress sx={{ ...logoAnimationStyle, borderRadius: 2, height: 1.5 }} variant="determinate" value={-1} />
        </Stack>
    )
}


function DesktopNavMenu() {
    return (
        <NavMenu sx={{ mb: -0.5, ml: 2 }}>
            <NavItem label="Dashboard" to="/dashboard" exact={true} />
            <NavItem label="Machines" to="/Machines" exact={true} />
            <NavItem label="Devices" to="/Devices" exact={true} />
        </NavMenu>
    )
}

function MobileNavMenu({ open, onClose }) {
    const location = useLocation()

    React.useEffect(() => {
        onClose()
    }, [location])

    return (
        <Collapse in={open} sx={{ width: '100%' }}>
            <Divider />
            <Box sx={{ width: '100%', my: 2 }}>
                <MNavItem label="Dashboard" to="/dashboard" exact={true} />
                <MNavItem label="Machines" to="/Machines" exact={true} />
                <MNavItem label="Devices" to="/Devices" exact={true} />
            </Box>
        </Collapse>
    );
}

function NavMenu({ children, ...rProps }) {
    const sx = { display: 'flex' }
    rProps.sx = ('sx' in rProps) ? { ...(rProps.sx), ...sx } : sx

    return (
        <Box {...rProps}>
            {children}
        </Box>
    );
}

function NavItem({ label, to = "", subMenu, exact = false }) {
    const location = useLocation()
    const active = to ? pathMatch(location.pathname, to, exact) : false

    const [menuOpen, setMenuOpen] = React.useState(false)
    const [menuWidth, setMenuWidth] = React.useState(100)
    const boxRef = React.useRef(null)

    function openSubMenu(e) {
        e.preventDefault();
        setMenuOpen(true);
    }
    React.useEffect(() => {
        if (boxRef.current && menuWidth !== boxRef.current.offsetWidth)
            setMenuWidth(boxRef.current.offsetWidth)
    }, [menuWidth])

    return (
        <Box ref={boxRef}>
            <Button disableRipple={true} {...(to ? { component: Link, to: to } : { component: "div", onClick: openSubMenu })}
                sx={{ textTransform: 'capitalize', fontWeight: 'normal', borderRadius: 5, px: 2, color: (active ? 'primary.main' : 'text.secondary') }}
            >
                <Typography variant='subtitle2' fontWeight={active ? "bold" : undefined}>{label}</Typography>
                {
                    subMenu &&
                    <Button onClick={openSubMenu}
                        sx={{ px: 0.5, my: -0.5, py: 0.5, ml: 0.5, mr: -0.5, minWidth: 0, color: (active ? 'primary.main' : 'text.secondary') }}
                    >
                        <KeyboardArrowDownIcon />
                    </Button>
                }
            </Button>
            {
                subMenu &&
                <Menu anchorEl={boxRef.current} open={menuOpen} onClose={() => { setMenuOpen(false) }}
                    sx={{ '& .MuiMenuItem-root': { p: 0 } }}
                    MenuListProps={{ sx: { minWidth: menuWidth + 'px' } }}
                    PopoverClasses={{ className: 'shadow-sm' }}
                >
                    {
                        subMenu.props.children.map((item, index) => {
                            return <MenuItem key={index} onClick={() => { setMenuOpen(false) }}>{item}</MenuItem>
                        })
                    }
                </Menu>
            }
        </Box>
    )
}

function NavSubItem({ label, to }) {
    const location = useLocation()
    const active = pathMatch(location.pathname, to)

    return (
        <Button disableRipple={true} component={Link} to={to} fullWidth
            sx={{ justifyContent: 'start', px: 2, textTransform: 'capitalize', fontWeight: 'normal', color: (active ? 'primary.main' : 'text.secondary') }}
        >
            <Typography variant='subtitle2' fontWeight={active ? "bold" : undefined}>{label}</Typography>
        </Button>
    );
}

function MNavItem({ label, to = "", subMenu, exact = false }) {
    const location = useLocation()
    const active = to ? pathMatch(location.pathname, to, exact) : false

    const [menuOpen, setMenuOpen] = React.useState(false)

    function openSubMenu(e) {
        e.preventDefault();
        setMenuOpen(!menuOpen);
    }

    return (
        <Box>
            <Button {...(to ? { component: Link, to: to } : { component: "div", onClick: openSubMenu })} fullWidth disableRipple
                sx={{ pl: 2, justifyContent: 'start', textTransform: 'capitalize', fontWeight: 'normal', color: (active ? 'primary.main' : 'text.secondary') }}
            >
                <Typography variant='subtitle2' fontWeight={active ? "bold" : undefined}>{label}</Typography>
                {
                    subMenu &&
                    <Button onClick={openSubMenu}
                        sx={{ ml: 'auto', px: 1, my: -0.5, py: 0.5, minWidth: 0, color: (active ? 'primary.main' : 'text.secondary') }}
                    >
                        {menuOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </Button>
                }
            </Button>
            {
                subMenu &&
                <Collapse in={menuOpen} sx={{ width: '100%' }}>
                    <Box sx={{ width: '100%' }}>
                        {subMenu}
                    </Box>
                </Collapse>
            }
        </Box>
    )
}

function MNavSubItem({ label, to }) {
    const location = useLocation()
    const active = pathMatch(location.pathname, to)

    return (
        <Button component={Link} to={to} fullWidth disableRipple
            sx={{ justifyContent: 'start', px: 2, pl: 4, textTransform: 'capitalize', fontWeight: 'normal', color: (active ? 'primary.main' : 'text.secondary') }}
        >
            <Typography variant='subtitle2' fontWeight={active ? "bold" : undefined}>{label}</Typography>
        </Button>
    );
}


function pathMatch(srcPath, matchPath, exact = false) {
    if (exact && srcPath.length !== matchPath.length)
        return false
    return Boolean(srcPath.slice(0, matchPath.length).match(matchPath))
}