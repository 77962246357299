import { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Stack, Grid, Divider, Skeleton, IconButton, Card, Chip, Typography, TextField, Container, CardMedia, Box, Tooltip, LinearProgress } from '@mui/material';
import { Button, Alert } from '@mui/material';
import { List, ListItem, ListItemButton, ListItemText, ListItemAvatar, ListItemIcon, Avatar } from '@mui/material';

import { Splitter, SplitterPanel } from 'primereact/splitter';

import axios from 'axios';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { formatDistanceToNow, format } from 'date-fns';

import { pb } from '../utilities/pocketbase';

import DevicesIcon from '@mui/icons-material/Devices';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlaceIcon from '@mui/icons-material/Place';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';

import { BsWindows, BsApple } from 'react-icons/bs'
import { DiLinux } from 'react-icons/di'
import { FcLinux, FcAndroidOs } from "react-icons/fc";
import { ImAndroid } from "react-icons/im";
import { FaAndroid } from "react-icons/fa";

export default function Devices() {
    const theme = useTheme()
    const [devices, setDevices] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [showAddress, setShowAddress] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [refresh, setRefresh] = useState(true)
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    const location = useLocation()

    const xsScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    useEffect(() => {
        if (selectedIndex >= 0) {
            const { latitude, longitude, address } = devices?.[selectedIndex]?.browserInfo
            if (!address && devices.length > 0 && latitude && longitude && showAddress && process.env.REACT_APP_GEOCODE_API_KEY) {
                axios.get(`https://geocode.maps.co/reverse?lat=${latitude}&lon=${longitude}&api_key=${process.env.REACT_APP_GEOCODE_API_KEY}`).then(response => {
                    console.log("address:", response)
                    const currentDevice = devices?.[selectedIndex]
                    currentDevice.address = response?.data?.display_name
                    const updatedDevices = [...devices.slice(0, selectedIndex), currentDevice, ...devices.slice(selectedIndex + 1)]
                    setDevices(updatedDevices)
                    setShowAddress(false)
                }).catch(error => {
                    console.log("Geocode error: ", error)
                    setShowAddress(false)
                }).finally(() => {
                    setShowAddress(false)
                })
            }
        }
    }, [showAddress, selectedIndex, devices])

    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }
        window.addEventListener('resize', handleResize)

        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    })

    useEffect(() => {
        if (pb?.authStore?.model?.id && refresh) {
            setLoading(true)
            pb.collection('recent_devices')
                // .getList(1, 50).then(deviceList => {
                .getFullList({ sort: "-created" }).then(deviceList => {
                    // console.log("deviceList: ", deviceList)
                    setDevices(deviceList)
                    setLoading(false)
                    setRefresh(false)
                }).catch(error => {
                    console.log("machine list error: ", error)
                    setLoading(false)
                    setRefresh(false)
                })
        }
    }, [refresh])

    useEffect(() => {
        if (location && devices.length > 0) {
            const searchIds = location?.search.split("?").filter(x => x && x)
            const searchId = searchIds[0]?.split("id=")?.[1]
            if (searchId) {
                const foundIndex = devices.map((x, index) => x?.id === searchId && index + 1).filter(x => x && x)[0]
                setSelectedIndex(foundIndex ? foundIndex - 1 : -1)
            }
        }
    }, [location, devices])

    return (
        <Grid container gap={1} justifyContent={"center"} alignItems={"flex-start"} sx={{ p: 1 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Splitter layout={smScreen ? "vertical" : "horizontal"}>
                    <SplitterPanel size={30}>
                        <Stack sx={{ borderRadius: 3, border: 1, borderColor: "divider", pb: 2, ...(!smScreen && { boxShadow: 2 }), ...(smScreen && selectedIndex >= 0 && { display: "none" }) }}>
                            <ListItem key={devices?.[selectedIndex]?.id}>
                                <ListItemText primary={"Devices"} secondary={`Total ${devices.length}`} />
                                <ListItemIcon>
                                    <Tooltip title={"Refresh"}>
                                        <IconButton onClick={() => setRefresh(true)}>
                                            <RefreshRoundedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </ListItemIcon>
                            </ListItem>
                            {loading ? <LinearProgress sx={{ height: 2 }} /> : <Divider />}
                            <Box sx={{ minHeight: dimensions.height - 190, maxHeight: dimensions.height - 190, overflow: "auto", scrollbarWidth: "thin" }}>
                                <List>
                                    {devices && devices.length > 0 && devices.map((device, index) => (
                                        <ListItemButton key={device?.id} selected={selectedIndex === index} onClick={(event) => handleListItemClick(event, index)} divider>
                                            <ListItemAvatar>
                                                <Avatar variant='rounded' sx={{ background: "transparent" }} >
                                                    <DevicesIcon color='primary' />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={device?.deviceId} secondary={`${device?.id} • ${formatDistanceToNow(device?.created)} ago`} />
                                            <ListItemIcon>
                                                {device?.browserInfo?.deviceInfo?.operatingSystem && device?.browserInfo?.deviceInfo?.operatingSystem + "".toLowerCase() === 'windows' && <BsWindows size={"1rem"} color="#0078D6" />}
                                                {device?.browserInfo?.deviceInfo?.operatingSystem && device?.browserInfo?.deviceInfo?.operatingSystem + "".toLowerCase() === 'android' && <ImAndroid size={"1rem"} color='#3DDC84' />}
                                                {device?.browserInfo?.deviceInfo?.operatingSystem && device?.browserInfo?.deviceInfo?.operatingSystem + "".toLowerCase() === 'linux' && <FcLinux size={"1rem"} color='#3DDC84' />}
                                                {device?.browserInfo?.deviceInfo?.operatingSystem && ["macos", "ios", "ipados", "visionos"].includes(device?.browserInfo?.deviceInfo?.operatingSystem + "".toLowerCase()) && <BsApple size={"1rem"} color="#6C6C6C" />}
                                            </ListItemIcon>
                                        </ListItemButton>
                                    ))}
                                    {loading && devices && devices.length === 0 && [...Array(12)].map((_, index) => (
                                        <Skeleton key={index} animation="wave" height={60} variant='rectangle' sx={{ borderBottom: 1, borderColor: "divider" }} />
                                    ))}
                                </List>
                            </Box>
                        </Stack>
                    </SplitterPanel>
                    <SplitterPanel size={70}>
                        {devices && devices.length > 0 && selectedIndex >= 0 ?
                            <Stack sx={{ borderRadius: 3, border: 1, borderColor: "divider", pb: 2, ...(!smScreen && { boxShadow: 2 }), ...(smScreen && selectedIndex < 0 && { display: "none" }) }}>
                                <ListItem key={devices?.[selectedIndex]?.id} divider>
                                    <ListItemIcon>
                                        <IconButton onClick={() => setSelectedIndex(-1)} >
                                            <ArrowBackIcon />
                                        </IconButton>
                                    </ListItemIcon>
                                    <ListItemAvatar>
                                        <Avatar variant='rounded' sx={{ background: "transparent" }} >
                                            <DevicesIcon color='primary' />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={devices?.[selectedIndex]?.deviceId} secondary={`${devices?.[selectedIndex]?.id} • ${format(devices?.[selectedIndex]?.created, "Ppp")}`} />
                                    {!smScreen && <ListItemIcon>
                                        {devices?.[selectedIndex]?.browserInfo?.deviceInfo?.operatingSystem && devices?.[selectedIndex]?.browserInfo?.deviceInfo?.operatingSystem + "".toLowerCase() === 'windows' && <Chip icon={<BsWindows size={"1rem"} color="#0078D6" />} sx={{ p: 2, border: 1, borderColor: "divider" }} label="Windows" variant="filled" />}
                                        {devices?.[selectedIndex]?.browserInfo?.deviceInfo?.operatingSystem && devices?.[selectedIndex]?.browserInfo?.deviceInfo?.operatingSystem + "".toLowerCase() === 'android' && <Chip icon={<ImAndroid size={"1rem"} color='#3DDC84' />} sx={{ p: 2, border: 1, borderColor: "divider" }} label="Android" variant="filled" />}
                                        {devices?.[selectedIndex]?.browserInfo?.deviceInfo?.operatingSystem && devices?.[selectedIndex]?.browserInfo?.deviceInfo?.operatingSystem + "".toLowerCase() === 'linux' && <Chip icon={<FcLinux size={"1rem"} color='#3DDC84' />} sx={{ p: 2, border: 1, borderColor: "divider" }} label="Linux" variant="filled" />}
                                        {devices?.[selectedIndex]?.browserInfo?.deviceInfo?.operatingSystem && ["macos", "ios", "ipados", "visionos"].includes(devices?.[selectedIndex]?.browserInfo?.deviceInfo?.operatingSystem + "".toLowerCase()) && <Chip icon={<BsApple size={"1rem"} color="#6C6C6C" />} sx={{ p: 2, border: 1, borderColor: "divider" }} label="Mac" variant="filled" />}
                                    </ListItemIcon>}
                                </ListItem>
                                <Box sx={{ maxHeight: dimensions.height - 190, overflow: "auto", scrollbarWidth: "thin", py: 2, px: 1 }}>
                                    <DeviceDetails data={devices?.[selectedIndex]} setShowAddress={setShowAddress} smScreen={smScreen} />
                                </Box>
                            </Stack>
                            :
                            <Stack sx={{ borderRadius: 3, border: 1, borderColor: "divider", pb: 2, boxShadow: 2, ...(smScreen && selectedIndex < 0 && { display: "none" }), minHeight: dimensions.height - 100, maxHeight: dimensions.height - 100, py: 2, px: 1 }} justifyContent={"center"} alignItems={"center"}>
                                <Chip size='medium' label={"Select a device to see information"} />
                            </Stack>
                        }
                    </SplitterPanel>
                </Splitter>
            </Grid>
        </Grid>
    )
}

const DeviceDetails = ({ data, setShowAddress, smScreen }) => {

    return (
        <Grid id={data?.id} container gap={2} justifyContent={"center"} alignItems={"flex-start"} sx={{ wordBreak: "break-all" }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container gap={1} justifyContent={"flex-start"} alignItems={"center"}>
                    {data?.browserInfo?.deviceInfo?.operatingSystem && data?.browserInfo?.deviceInfo?.operatingSystem + "".toLowerCase() === 'windows' && smScreen && <Chip icon={<BsWindows size={"1rem"} color="#0078D6" />} sx={{ p: 2, border: 1, borderColor: "divider" }} label="Windows" variant="filled" />}
                    {data?.browserInfo?.deviceInfo?.operatingSystem && data?.browserInfo?.deviceInfo?.operatingSystem + "".toLowerCase() === 'android' && smScreen && <Chip icon={<ImAndroid size={"1rem"} color='#3DDC84' />} sx={{ p: 2, border: 1, borderColor: "divider" }} label="Android" variant="filled" />}
                    {data?.browserInfo?.deviceInfo?.operatingSystem && data?.browserInfo?.deviceInfo?.operatingSystem + "".toLowerCase() === 'linux' && smScreen && <Chip icon={<FcLinux size={"1rem"} color='#3DDC84' />} sx={{ p: 2, border: 1, borderColor: "divider" }} label="Linux" variant="filled" />}
                    {data?.browserInfo?.deviceInfo?.operatingSystem && ["macos", "ios", "ipados", "visionos"].includes(data?.browserInfo?.deviceInfo?.operatingSystem + "".toLowerCase()) && smScreen && <Chip icon={<BsApple size={"1rem"} color="#6C6C6C" />} sx={{ p: 2, border: 1, borderColor: "divider" }} label="Mac" variant="filled" />}
                    {["accountInfo", "deviceInfo", "browserInfo"].map((key, index) => (
                        <Grid item key={key}>
                            <Chip size='medium' label={key} variant='outlined' sx={{ p: 2 }} onClick={() => { document.getElementById(key)?.scrollIntoView({ behavior: "smooth" }) }} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            {data?.did && data?.machine && <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box id={"accountInfo"} sx={{ border: 1, borderColor: "divider", borderRadius: 3 }}>
                    <Typography sx={{ m: 2 }} variant='subtitle1' fontWeight={"bold"}>Account Info</Typography>
                    <Divider />
                    <Box sx={{ overflow: "auto", scrollbarWidth: "thin", p: 2 }}>
                        {Object.keys(data).filter(x => ["did", "machine"].includes(x) && x).map(key => (
                            <Stack direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant='subtitle2' color={"text.secondary"}>{key}</Typography>
                                {key !== "machine" && <Typography fontFamily={"JetBrains Mono"} variant='subtitle1'>{"" + data?.[key]}</Typography>}
                                {key === "machine" && <Typography component={RouterLink} color={"primary.main"} to={`/Machines?id=${data?.[key]}`} fontFamily={"JetBrains Mono"} variant='subtitle1'>{"" + data?.[key]}</Typography>}
                            </Stack>
                        ))}
                    </Box>
                    <Divider />
                    <Box sx={{ p: 2 }}>
                        {!data?.address && <Chip label={"Show Address"} variant='outlined' sx={{ py: 2 }} icon={<PlaceIcon />} onClick={() => setShowAddress(true)} />}
                        {data?.address && <Alert severity='info' icon={<PlaceIcon />} sx={{ border: 2, borderRadius: 3 }}>{data?.address}</Alert>}
                    </Box>
                    <Divider />
                    <Typography variant='subtitle2' fontWeight={"light"} color={"text.disabled"} align='center' sx={{ m: 2 }}>{data?.deviceId}</Typography>
                </Box>
            </Grid>}
            {data?.browserInfo?.deviceInfo && <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box id={"deviceInfo"} sx={{ border: 1, borderColor: "divider", borderRadius: 3 }}>
                    <Typography sx={{ m: 2 }} variant='subtitle1' fontWeight={"bold"}>Device Info</Typography>
                    <Divider />
                    <Box sx={{ overflow: "auto", scrollbarWidth: "thin", p: 2 }}>
                        {Object.keys(data?.browserInfo?.deviceInfo).map(key => (
                            <Stack direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant='subtitle2' color={"text.secondary"}>{key}</Typography>
                                <Typography variant='subtitle1'>{"" + data?.browserInfo?.deviceInfo?.[key]}</Typography>
                            </Stack>
                        ))}
                    </Box>
                    <Divider />
                    <Typography variant='subtitle2' fontWeight={"light"} color={"text.disabled"} align='center' sx={{ m: 2 }}>{data?.deviceId}</Typography>
                </Box>
            </Grid>}
            {data?.browserInfo && <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box id={"browserInfo"} sx={{ border: 1, borderColor: "divider", borderRadius: 3 }}>
                    <Typography sx={{ m: 2 }} variant='subtitle1' fontWeight={"bold"}>Browser Info</Typography>
                    <Divider />
                    <Box sx={{ overflow: "auto", scrollbarWidth: "thin", p: 2 }}>
                        {Object.keys(data?.browserInfo).filter(x => !["deviceId", "deviceInfo"].includes(x) && x).map(key => (
                            <Stack direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant='subtitle2' color={"text.secondary"}>{key}</Typography>
                                {key !== "did" && key !== "peerId" && <Typography variant='subtitle1'>{"" + data?.browserInfo?.[key]}</Typography>}
                                {(key === "did" || key === "peerId") && <Typography fontFamily={"JetBrains Mono"} variant='subtitle1'>{"" + data?.browserInfo?.[key]}</Typography>}
                            </Stack>
                        ))}
                    </Box>
                    <Divider />
                    <Typography variant='subtitle2' fontWeight={"light"} color={"text.disabled"} align='center' sx={{ m: 2 }}>{data?.deviceId}</Typography>
                </Box>
            </Grid>}
        </Grid>
    )
}