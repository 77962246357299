import * as React from 'react';
import { Button, Card, CardContent, Chip, Dialog, Divider, IconButton, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { format } from 'date-fns'

export function ShowDateTime({ currentTime, smScreen }) {
    const [openMoment, setOpenMoment] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const toggleMoment = () => {
        setOpenMoment(!openMoment)
    }

    return (
        <>
            {!smScreen &&
                <Button
                    sx={{ color: 'text.secondary', mr: { xs: 0, md: 2 }, textTransform: 'none', borderRadius: 5, border: 2, px: 2 }}
                    onClick={toggleMoment}
                >
                    {format(currentTime, "Pp")}
                </Button>
            }
            {smScreen &&
                <Tooltip title="Show current time">
                    <IconButton onClick={toggleMoment}><AccessTimeIcon /></IconButton>
                </Tooltip>
            }
            <Menu
                id="demo-positioned-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <MenuItem onMouseLeave={handleClose} onClick={() => {
                    setAnchorEl(null);
                    toggleMoment()
                }}>
                    {/* {currentTime.toUTCString().replace("GMT", "UTC")} */}
                    <Stack>
                        <Typography variant='h6'>{currentTime.toUTCString().substring(17, 25)}</Typography>
                        <Typography variant='subtitle2' align='left' color={'primary'}>{currentTime.toUTCString().substring(0, 17)}</Typography>
                    </Stack>

                </MenuItem>
            </Menu>
            <CurrentMoment currentTime={currentTime} openMoment={openMoment} toggleMoment={toggleMoment} />
        </>
    )
}

function CurrentMoment({ currentTime, openMoment, toggleMoment }) {
    return (
        <Dialog open={openMoment} onClose={toggleMoment} PaperProps={{ sx: { borderRadius: 3 } }}>
            <Card>
                <CardContent sx={{ mt: 0 }}>
                    <Typography variant='caption' fontWeight={"bold"} color={'text.secondary'}>UTC time</Typography>
                    <Stack spacing={2} direction={'row'} alignItems='center'>
                        <Typography variant='h4'>{currentTime.toUTCString().substring(17, 25)}</Typography>
                    </Stack>
                    <Typography variant='subtitle2' align='left' color={'primary'}>{currentTime.toUTCString().substring(0, 17)}</Typography>
                </CardContent>
                <Divider />
                <CardContent sx={{ mt: 0 }}>
                    <Typography variant='caption' fontWeight={"bold"} color={'text.secondary'}>{"Local time " + Date().toLocaleString().substring(34)}</Typography>
                    <Stack spacing={2} direction={'row'} alignItems='center'>
                        <Typography variant='h4'>{format(currentTime, "hh:mm:ss")}</Typography>
                        <Typography variant='subtitle2' color={'text.secondary'}>{format(currentTime, "aa")}</Typography>
                    </Stack>
                    <Typography variant='subtitle2' align='left' color={'primary'}>{format(currentTime, "PPPP")}</Typography>
                </CardContent>
                <Divider />
                <Button fullWidth size='large' sx={{ borderRadius: 0, p: 2 }} onClick={toggleMoment} color="error">Close</Button>
            </Card>
        </Dialog>
    )
}