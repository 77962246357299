import { Navigate, Outlet, useLocation } from "react-router-dom";

import { pb } from "../utilities/pocketbase";


function checkAuth(location) {
    // handling of logout when server returns 403 and the 
    // client indicate it through RequireAuth url query param
    const qp = new URLSearchParams(location.search);
    if (location.pathname === '/' && qp.has('RequireAuth')) {
        return false
    }
    if (pb.authStore.isValid)
        return true
    else
        return false
}

function AuthRequiredComponent({ noAuth = null, auth = null, children = null }) {
    const location = useLocation()

    if (!auth && children) {
        auth = children
    }
    if (checkAuth(location))
        return auth;
    else {
        return noAuth;
    }
}

function AuthRequiredRoutes() {
    const location = useLocation()

    return (
        checkAuth(location) ? <Outlet /> : <Navigate to={{ pathname: "/", search: "?RequireAuth" }} />
    );
}

export { AuthRequiredComponent, AuthRequiredRoutes }