import { useEffect } from "react";
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useLocation, Link } from 'react-router-dom';

export default function PageNotFound() {
    const location = useLocation()

    // setting page title
    useEffect(() => {
        window.document.title = 'Page Not Found 404 !'
        return () => { window.document.title = process.env.REACT_APP_NAME }
    }, [])

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', height: 'calc(100% - 6em)', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ textAlign: 'center', py: '3em', px: '1em' }}>
                <Typography variant='h1'>404</Typography>
                <Typography variant='h3'>Page Not Found</Typography>
                <br />
                <Typography variant='subtitle1' sx={{ my: 2 }}>
                    The page <Typography component='span' sx={{ bgcolor: 'grey.400', px: '5px' }}>{location.pathname}</Typography> does not Exist
                </Typography>
                <Button component={Link} to='/' sx={{ textTransform: "none", py: 2, px: 6 }} color="secondary">Go home</Button>
            </Box>
        </Box>
    );
}