import { useState, useEffect } from 'react';
import { Stack, Grid, Typography, Box, Divider, Chip, IconButton, ToggleButtonGroup, ToggleButton, Skeleton } from '@mui/material';
import { List, ListItem, ListItemButton, ListItemText, ListItemAvatar, ListItemIcon, Avatar, LinearProgress, Button, Tooltip, Pagination } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useLocation, useNavigate } from "react-router-dom";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Splitter, SplitterPanel } from 'primereact/splitter';

import { format, formatDistanceToNow } from 'date-fns';
import { formatBytes, formatDuration } from '../utilities/common';

import { pb } from '../utilities/pocketbase';

import ComputerIcon from '@mui/icons-material/Computer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FolderIcon from '@mui/icons-material/Folder';
import RefreshIcon from '@mui/icons-material/Refresh';
import StorageIcon from '@mui/icons-material/Storage';
import MemoryIcon from '@mui/icons-material/Memory';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';

import { BsWindows, BsApple } from 'react-icons/bs'
import { DiLinux } from 'react-icons/di'
import { FcLinux, FcAndroidOs } from "react-icons/fc";
import { ImAndroid } from "react-icons/im";
import { FaAndroid } from "react-icons/fa";

export default function Machines() {
    const theme = useTheme()
    const [machines, setMachines] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [infoType, setInfoType] = useState('static');
    const [markerData, setMarkerData] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [refresh, setRefresh] = useState(true)
    // const [page, setPage] = useState(1);
    // const [rowsPerPage, setRowsPerPage] = useState(10);
    // const [totalPages, setTotalPages] = useState(1)
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    const location = useLocation()
    const navigate = useNavigate();

    const xsScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));

    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage);
    // };

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        navigate(`?id=${machines?.[index]?.id}`)
    };

    const handleInfoType = (event, newInfoType) => {
        if (newInfoType !== null) {
            setInfoType(newInfoType);
        }
    };

    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }
        window.addEventListener('resize', handleResize)

        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    })


    useEffect(() => {
        if (pb?.authStore?.model?.id && refresh) {
            setLoading(true)
            pb.collection('machine_list')
                // .getList(page, rowsPerPage).then(machinesList => {
                .getFullList({ sort: "-updated" }).then(machinesList => {
                    console.log("machineList: ", machinesList)
                    setMachines(machinesList)
                    // setTotalPages(machinesList?.totalPages)
                    let liveLocations = machinesList
                        // .filter(key => data[key]?.timestamp && (new Date().getTime() - new Date(data[key]?.timestamp).getTime()) < 5 * 60 * 1000 && key) // 5 min filter
                        .filter(item => item?.id && item?.machineId && item?.staticInfo && item?.staticInfo?.ipInfo && item)
                        .map(x => ({
                            id: x?.machineId,
                            lat: x?.staticInfo?.ipInfo?.loc?.split(",")?.[0],
                            lng: x?.staticInfo?.ipInfo?.loc?.split(",")?.[1],
                            timestamp: x?.updated,
                            ipInfo: x?.staticInfo?.ipInfo
                        }))
                    console.log("liveLocations:", liveLocations, liveLocations.length)
                    setMarkerData(liveLocations)
                    setLoading(false)
                    setRefresh(false)
                }).catch(error => {
                    console.log("machine list error: ", error)
                    setLoading(false)
                    setRefresh(false)
                })
        }
    }, [refresh])

    useEffect(() => {
        if (pb?.authStore?.model?.id && selectedIndex >= 0 && machines && machines.length > 0 && !machines?.[selectedIndex]?.dynamicInfo && machines?.[selectedIndex]?.id) {
            pb.collection('machines').getOne(machines?.[selectedIndex]?.id).then(machine => {
                const prevMachines = machines
                const newMachines = [
                    ...prevMachines.slice(0, selectedIndex),
                    machine,
                    ...prevMachines.slice(selectedIndex + 1)
                ]
                setMachines(newMachines)
            }).catch(error => {
                console.log("machine view error:", error)
            })
        }
    }, [selectedIndex, machines])

    useEffect(() => {
        if (location && machines.length > 0) {
            const searchIds = location?.search.split("?").filter(x => x && x)
            const searchId = searchIds[0]?.split("id=")?.[1]
            if (searchId) {
                const foundIndex = machines.map((x, index) => x?.id === searchId && index + 1).filter(x => x && x)[0]
                setSelectedIndex(foundIndex ? foundIndex - 1 : -1)
            }
        }
    }, [location, machines])

    return (
        <Grid container gap={1} justifyContent={"center"} alignItems={"flex-start"} sx={{ p: 1 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Splitter layout={smScreen ? "vertical" : "horizontal"}>
                    <SplitterPanel size={30}>
                        <Stack sx={{ borderRadius: 3, border: 1, borderColor: "divider", pb: 2, ...(!smScreen && { boxShadow: 2 }), ...(smScreen && selectedIndex >= 0 && { display: "none" }) }}>
                            <ListItem key={machines?.[selectedIndex]?.id}>
                                <ListItemText primary={"Machines"} secondary={`Total ${machines.length}`} />
                                <ListItemIcon>
                                    <Tooltip title={"Refresh"}>
                                        <IconButton onClick={() => setRefresh(true)}>
                                            <RefreshRoundedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </ListItemIcon>
                            </ListItem>
                            {loading ? <LinearProgress sx={{ height: 2 }} /> : <Divider />}
                            <Box sx={{ minHeight: dimensions.height - 190, maxHeight: dimensions.height - 190, overflow: "auto", scrollbarWidth: "thin" }}>
                                <List>
                                    {machines && machines.length > 0 && machines.map((machine, index) => (
                                        <ListItemButton key={machine?.id} selected={selectedIndex === index} onClick={(event) => handleListItemClick(event, index)} divider>
                                            <ListItemAvatar>
                                                <Avatar variant='rounded' sx={{ background: "transparent" }} >
                                                    <ComputerIcon color='primary' />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={machine?.machineId} secondary={`${machine?.id} • ${formatDistanceToNow(machine?.updated)}`} />
                                            <ListItemIcon>
                                                {machine?.staticInfo?.os?.platform && machine?.staticInfo?.os?.platform?.toLowerCase() === 'windows' && <BsWindows size={"1rem"} color="#0078D6" />}
                                                {machine?.staticInfo?.os?.platform && machine?.staticInfo?.os?.platform?.toLowerCase() === 'android' && <ImAndroid size={"1rem"} color='#3DDC84' />}
                                                {machine?.staticInfo?.os?.platform && machine?.staticInfo?.os?.platform?.toLowerCase() === 'linux' && <FcLinux size={"1rem"} color='#3DDC84' />}
                                                {machine?.staticInfo?.os?.platform && ["macos", "ios", "ipados", "visionos", "darwin"].includes(machine?.staticInfo?.os?.platform?.toLowerCase()) && <BsApple size={"1rem"} color="#6C6C6C" />}
                                            </ListItemIcon>
                                        </ListItemButton>
                                    ))}
                                    {loading && machines && machines.length === 0 && [...Array(12)].map((_, index) => (
                                        <Skeleton key={index} animation="wave" height={60} variant='rectangle' sx={{ borderBottom: 1, borderColor: "divider" }} />
                                    ))}
                                </List>
                            </Box>
                        </Stack>
                    </SplitterPanel>
                    <SplitterPanel size={70}>
                        {machines && machines.length > 0 && selectedIndex >= 0 ?
                            <Stack sx={{ borderRadius: 3, border: 1, borderColor: "divider", pb: 2, ...(!smScreen && { boxShadow: 2 }), ...(smScreen && selectedIndex < 0 && { display: "none" }) }}>
                                <ListItem key={machines?.[selectedIndex]?.id}>
                                    <ListItemIcon>
                                        <IconButton onClick={() => setSelectedIndex(-1)} >
                                            <ArrowBackIcon />
                                        </IconButton>
                                    </ListItemIcon>
                                    <ListItemAvatar>
                                        <Avatar variant='rounded' sx={{ background: "transparent" }} >
                                            <ComputerIcon color='primary' />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={machines?.[selectedIndex]?.machineId} secondary={`${machines?.[selectedIndex]?.id} • ${format(machines?.[selectedIndex]?.updated, "Ppp")}`} />
                                    {!smScreen && <ListItemIcon>
                                        {machines?.[selectedIndex]?.staticInfo?.os?.platform && machines?.[selectedIndex]?.staticInfo?.os?.platform?.toLowerCase() === 'windows' && <Chip icon={<BsWindows size={"1rem"} color="#0078D6" />} sx={{ p: 2, border: 1, borderColor: "divider" }} label="Windows" variant="filled" />}
                                        {machines?.[selectedIndex]?.staticInfo?.os?.platform && machines?.[selectedIndex]?.staticInfo?.os?.platform?.toLowerCase() === 'android' && <Chip icon={<ImAndroid size={"1rem"} color='#3DDC84' />} sx={{ p: 2, border: 1, borderColor: "divider" }} label="Android" variant="filled" />}
                                        {machines?.[selectedIndex]?.staticInfo?.os?.platform && machines?.[selectedIndex]?.staticInfo?.os?.platform?.toLowerCase() === 'linux' && <Chip icon={<FcLinux size={"1rem"} color='#3DDC84' />} sx={{ p: 2, border: 1, borderColor: "divider" }} label="Linux" variant="filled" />}
                                        {machines?.[selectedIndex]?.staticInfo?.os?.platform && ["macos", "ios", "ipados", "visionos", "mac"].includes(machines?.[selectedIndex]?.staticInfo?.os?.platform?.toLowerCase()) && <Chip icon={<BsApple size={"1rem"} color="#6C6C6C" />} sx={{ p: 2, border: 1, borderColor: "divider" }} label="Mac" variant="filled" />}
                                    </ListItemIcon>}
                                </ListItem>
                                <ToggleButtonGroup
                                    value={infoType}
                                    exclusive
                                    onChange={handleInfoType}
                                    fullWidth
                                    sx={{ boxShadow: 5, borderRadius: 0 }}
                                >
                                    <ToggleButton value="static" color='info' sx={{ borderRadius: 0, px: 2, textTransform: "none", borderLeft: 0 }}>
                                        Static Information
                                    </ToggleButton>
                                    <ToggleButton value="dynamic" color='warning' sx={{ borderRadius: 0, px: 2, textTransform: "none", borderRight: 0 }}>
                                        Dynamic Information
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                <Box sx={{ maxHeight: dimensions.height - 240, overflow: "auto", scrollbarWidth: "thin", py: 2, px: 1 }}>
                                    {infoType === "static" ? <MachineStaticInfos data={machines[selectedIndex]} smScreen={smScreen} /> : <MachineDynamicInfos data={machines[selectedIndex]} />}
                                </Box>
                            </Stack> : <Stack sx={{ borderRadius: 3, border: 1, borderColor: "divider", pb: 2, boxShadow: 2, ...(smScreen && selectedIndex < 0 && { display: "none" }), minHeight: dimensions.height - 100, maxHeight: dimensions.height - 100, py: 2, px: 1 }} justifyContent={"center"} alignItems={"center"}>
                                <Chip size='medium' label={"Select a machine to see information"} />
                            </Stack>}
                    </SplitterPanel>
                </Splitter>
            </Grid>
            {/* {!error && <Grid item xs={12} sm={12} md={12}>
                <Stack alignItems={"center"} sx={{ my: 2 }}>
                    <Pagination
                        color='secondary'
                        size={smScreen ? 'medium' : 'large'}
                        count={totalPages}
                        page={page}
                        onChange={handleChangePage}
                        variant="outlined"
                        shape="rounded"
                    />
                </Stack>
            </Grid>} */}
        </Grid >
    )
}

const MachineStaticInfos = ({ data, loading, error, smScreen }) => {
    const [staticKeys, setStaticKeys] = useState([])

    useEffect(() => {
        if (data && data?.staticInfo) {
            setStaticKeys(Object.keys(data?.staticInfo))
        }
    }, [data])

    return (
        <Grid id={data?.id} container gap={2} justifyContent={"center"} alignItems={"flex-start"} sx={{ wordBreak: "break-all" }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container gap={1} justifyContent={"flex-start"} alignItems={"center"}>
                    {data?.staticInfo?.os?.platform && data?.staticInfo?.os?.platform?.toLowerCase() === 'windows' && smScreen && <Chip icon={<BsWindows size={"1rem"} color="#0078D6" />} sx={{ p: 2, border: 1, borderColor: "divider" }} label="Windows" variant="filled" />}
                    {data?.staticInfo?.os?.platform && data?.staticInfo?.os?.platform?.toLowerCase() === 'android' && smScreen && <Chip icon={<ImAndroid size={"1rem"} color='#3DDC84' />} sx={{ p: 2, border: 1, borderColor: "divider" }} label="Android" variant="filled" />}
                    {data?.staticInfo?.os?.platform && data?.staticInfo?.os?.platform?.toLowerCase() === 'linux' && smScreen && <Chip icon={<FcLinux size={"1rem"} color='#3DDC84' />} sx={{ p: 2, border: 1, borderColor: "divider" }} label="Linux" variant="filled" />}
                    {data?.staticInfo?.os?.platform && ["macos", "ios", "ipados", "visionos", "mac"].includes(data?.staticInfo?.os?.platform?.toLowerCase()) && smScreen && <Chip icon={<BsApple size={"1rem"} color="#6C6C6C" />} sx={{ p: 2, border: 1, borderColor: "divider" }} label="Mac" variant="filled" />}
                    {staticKeys && staticKeys.length > 0 && staticKeys.map((key, index) => (
                        <Grid item key={key}>
                            <Chip size='medium' label={key} variant='outlined' sx={{ p: 2 }} onClick={() => { document.getElementById(key)?.scrollIntoView({ behavior: "smooth" }) }} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            {data && data?.staticInfo?.uuid && <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box id={"uuid"} sx={{ border: 1, borderColor: "divider", borderRadius: 3 }}>
                    <Typography sx={{ m: 2 }} variant='subtitle1' fontWeight={"bold"}>UUID</Typography>
                    <Divider />
                    <Box sx={{ overflow: "auto", scrollbarWidth: "thin", p: 2 }}>
                        {Object.keys(data?.staticInfo?.uuid).filter(key => !["macs"].includes(key)).map(key => (
                            <Stack key={key} direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant='subtitle2' color={"text.secondary"}>{key}</Typography>
                                <Typography variant='subtitle1' fontFamily={"JetBrains Mono"}>{data?.staticInfo?.uuid?.[key] === "" ? "N/A" : "" + data?.staticInfo?.uuid?.[key]}</Typography>
                            </Stack>
                        ))}
                        <Typography variant='subtitle2' color={"text.secondary"}>{"mac(s)"}</Typography>
                        {data?.staticInfo?.uuid?.macs.map((mac, index) => (<Stack key={index + mac}>
                            <Typography variant='subtitle2' fontFamily={"JetBrains Mono"}>MAC {index + 1}: {mac} </Typography>
                        </Stack>))}
                    </Box>
                    <Divider />
                    <Typography variant='subtitle2' fontWeight={"light"} color={"text.disabled"} align='center' sx={{ m: 2 }}>{data?.machineId}</Typography>
                </Box>
            </Grid>}
            {data && data?.staticInfo?.ipInfo && <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box id={"ipInfo"} sx={{ border: 1, borderColor: "divider", borderRadius: 3 }}>
                    <Typography sx={{ m: 2 }} variant='subtitle1' fontWeight={"bold"}>IP Info</Typography>
                    <Divider />
                    <Box sx={{ overflow: "auto", scrollbarWidth: "thin", p: 2 }}>
                        {Object.keys(data?.staticInfo?.ipInfo).map(key => (
                            <Stack key={key} direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant='subtitle2' color={"text.secondary"}>{key}</Typography>
                                <Typography variant='subtitle1' fontFamily={"JetBrains Mono"}>{data?.staticInfo?.ipInfo?.[key] === "" ? "N/A" : "" + data?.staticInfo?.ipInfo?.[key]}</Typography>
                            </Stack>
                        ))}
                    </Box>
                    <Divider />
                    <Typography variant='subtitle2' fontWeight={"light"} color={"text.disabled"} align='center' sx={{ m: 2 }}>{data?.machineId}</Typography>
                </Box>
            </Grid>}
            {data && data?.staticInfo?.IP2Location && <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box id={"IP2Location"} sx={{ border: 1, borderColor: "divider", borderRadius: 3 }}>
                    <Typography sx={{ m: 2 }} variant='subtitle1' fontWeight={"bold"}>IP2Location</Typography>
                    <Divider />
                    <Box sx={{ overflow: "auto", scrollbarWidth: "thin", p: 2 }}>
                        {Object.keys(data?.staticInfo?.IP2Location).map(key => (
                            <Stack key={key} direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant='subtitle2' color={"text.secondary"}>{key}</Typography>
                                <Typography variant='subtitle1' fontFamily={"JetBrains Mono"}>{data?.staticInfo?.IP2Location?.[key] === "" ? "N/A" : "" + data?.staticInfo?.IP2Location?.[key]}</Typography>
                            </Stack>
                        ))}
                    </Box>
                    <Divider />
                    <Typography variant='subtitle2' fontWeight={"light"} color={"text.disabled"} align='center' sx={{ m: 2 }}>{data?.machineId}</Typography>
                </Box>
            </Grid>}
            {data && data?.staticInfo?.system && <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box id={"system"} sx={{ border: 1, borderColor: "divider", borderRadius: 3 }}>
                    <Typography sx={{ m: 2 }} variant='subtitle1' fontWeight={"bold"}>System</Typography>
                    <Divider />
                    <Box sx={{ overflow: "auto", scrollbarWidth: "thin", p: 2 }}>
                        {Object.keys(data?.staticInfo?.system).map(key => (
                            <Stack key={key} direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant='subtitle2' color={"text.secondary"}>{key}</Typography>
                                <Typography variant='subtitle1' fontFamily={"JetBrains Mono"}>{data?.staticInfo?.system?.[key] === "" ? "N/A" : "" + data?.staticInfo?.system?.[key]}</Typography>
                            </Stack>
                        ))}
                    </Box>
                    <Divider />
                    <Typography variant='subtitle2' fontWeight={"light"} color={"text.disabled"} align='center' sx={{ m: 2 }}>{data?.machineId}</Typography>
                </Box>
            </Grid>}
            {data && data?.staticInfo?.cpu && <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box id={"cpu"} sx={{ border: 1, borderColor: "divider", borderRadius: 3 }}>
                    <Typography sx={{ m: 2 }} variant='subtitle1' fontWeight={"bold"}>CPU</Typography>
                    <Divider />
                    <Box sx={{ overflow: "auto", scrollbarWidth: "thin", p: 2 }}>
                        {Object.keys(data?.staticInfo?.cpu).filter(key => !['flags'].includes(key) && key).map(key => (
                            <Stack key={key} direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant='subtitle2' color={"text.secondary"}>{key}</Typography>
                                {key !== "cache" && <Typography variant='subtitle1' fontFamily={"JetBrains Mono"}>{data?.staticInfo?.cpu?.[key] || "N/A"}</Typography>}
                                {key === "cache" && <Stack direction={"row"} spacing={1} justifyContent={"flex-end"}>
                                    {Object.keys(data?.staticInfo?.cpu?.[key]).map((x, index) => (
                                        <Chip key={index} size='medium' label={`${x} • ${formatBytes(data?.staticInfo?.cpu?.[key]?.[x], 2)}`} />
                                    ))}
                                </Stack>
                                }
                            </Stack>
                        ))}
                    </Box>
                    <Divider />
                    <Typography variant='subtitle2' fontWeight={"light"} color={"text.disabled"} align='center' sx={{ m: 2 }}>{data?.machineId}</Typography>
                </Box>
            </Grid>}
            {data && data?.staticInfo?.graphics && <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box id={"graphics"} sx={{ border: 1, borderColor: "divider", borderRadius: 3 }}>
                    <Typography sx={{ m: 2 }} variant='subtitle1' fontWeight={"bold"}>Graphics</Typography>
                    <Divider />
                    <Box sx={{ overflow: "auto", scrollbarWidth: "thin", p: 2 }}>
                        {data?.staticInfo?.graphics?.controllers.map((controller, index) => (<Stack>
                            <Divider>
                                <Chip label={`controller ${index + 1}`} size="small" />
                            </Divider>
                            {Object.keys(controller).map(key => (
                                <Stack direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                                    <Typography variant='subtitle2' color={"text.secondary"}>{key}</Typography>
                                    {key !== "size" && <Typography variant='subtitle1' fontFamily={"JetBrains Mono"}>{controller?.[key] || "N/A"}</Typography>}
                                    {key === "size" && <Typography variant='subtitle1' fontFamily={"JetBrains Mono"}>{formatBytes(controller?.[key], 2) || "N/A"}</Typography>}
                                </Stack>
                            ))}
                        </Stack>))}
                        {data?.staticInfo?.graphics?.displays.map((display, index) => (<Stack key={index}>
                            <Divider>
                                <Chip label={`display ${index + 1}`} size="small" />
                            </Divider>
                            {Object.keys(display).map(key => (
                                <Stack key={key} direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                                    <Typography variant='subtitle2' color={"text.secondary"}>{key}</Typography>
                                    <Typography variant='subtitle1' fontFamily={"JetBrains Mono"}>{display?.[key] || "N/A"}</Typography>
                                </Stack>
                            ))}
                        </Stack>))}
                    </Box>
                    <Divider />
                    <Typography variant='subtitle2' fontWeight={"light"} color={"text.disabled"} align='center' sx={{ m: 2 }}>{data?.machineId}</Typography>
                </Box>
            </Grid>}
            {data && data?.staticInfo?.diskLayout && <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box id={"diskLayout"} sx={{ border: 1, borderColor: "divider", borderRadius: 3 }}>
                    <Typography sx={{ m: 2 }} variant='subtitle1' fontWeight={"bold"}>Disk Layout</Typography>
                    <Divider />
                    <Box sx={{ overflow: "auto", scrollbarWidth: "thin", p: 2 }}>
                        {data?.staticInfo?.diskLayout.map((disk, index) => (<Stack key={index}>
                            <Divider>
                                <Chip label={`disk ${index + 1}`} size="small" />
                            </Divider>
                            {Object.keys(disk).map(key => (
                                <Stack key={key} direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                                    <Typography variant='subtitle2' color={"text.secondary"}>{key}</Typography>
                                    {key !== "size" && <Typography variant='subtitle1' fontFamily={"JetBrains Mono"}>{disk?.[key] || "N/A"}</Typography>}
                                    {key === "size" && <Typography variant='subtitle1' fontFamily={"JetBrains Mono"}>{formatBytes(disk?.[key], 2) || "N/A"}</Typography>}
                                </Stack>
                            ))}
                        </Stack>))}
                    </Box>
                    <Divider />
                    <Typography variant='subtitle2' fontWeight={"light"} color={"text.disabled"} align='center' sx={{ m: 2 }}>{data?.machineId}</Typography>
                </Box>
            </Grid>}
            {data && data?.staticInfo?.memLayout && <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box id={"memLayout"} sx={{ border: 1, borderColor: "divider", borderRadius: 3 }}>
                    <Typography sx={{ m: 2 }} variant='subtitle1' fontWeight={"bold"}>Memory Layout</Typography>
                    <Divider />
                    <Box sx={{ overflow: "auto", scrollbarWidth: "thin", p: 2 }}>
                        {data?.staticInfo?.memLayout?.map((mem, index) => (<Stack key={index}>
                            <Divider>
                                <Chip label={`memory ${index + 1}`} size="small" />
                            </Divider>
                            {Object.keys(mem).map(key => (
                                <Stack key={key} direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                                    <Typography variant='subtitle2' color={"text.secondary"}>{key}</Typography>
                                    {key !== "size" && <Typography variant='subtitle1' fontFamily={"JetBrains Mono"}>{mem?.[key] || "N/A"}</Typography>}
                                    {key === "size" && <Typography variant='subtitle1' fontFamily={"JetBrains Mono"}>{formatBytes(mem?.[key], 2) || "N/A"}</Typography>}
                                </Stack>
                            ))}
                        </Stack>))}
                    </Box>
                    <Divider />
                    <Typography variant='subtitle2' fontWeight={"light"} color={"text.disabled"} align='center' sx={{ m: 2 }}>{data?.machineId}</Typography>
                </Box>
            </Grid>}
            {data && data?.staticInfo?.net && <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box id={"net"} sx={{ border: 1, borderColor: "divider", borderRadius: 3 }}>
                    <Typography sx={{ m: 2 }} variant='subtitle1' fontWeight={"bold"}>Network</Typography>
                    <Divider />
                    <Box sx={{ overflow: "auto", scrollbarWidth: "thin", p: 2 }}>
                        {data?.staticInfo?.net?.map((net, index) => (<Stack key={index}>
                            <Divider>
                                <Chip label={`network ${index + 1}`} size="small" />
                            </Divider>
                            {Object.keys(net).map(key => (
                                <Stack key={key} direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                                    <Typography variant='subtitle2' color={"text.secondary"}>{key}</Typography>
                                    <Typography variant='subtitle1' fontFamily={"JetBrains Mono"}>{net?.[key] || "N/A"}</Typography>
                                </Stack>
                            ))}
                        </Stack>))}
                    </Box>
                    <Divider />
                    <Typography variant='subtitle2' fontWeight={"light"} color={"text.disabled"} align='center' sx={{ m: 2 }}>{data?.machineId}</Typography>
                </Box>
            </Grid>}
            {data && data?.staticInfo?.os && <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box id={"os"} sx={{ border: 1, borderColor: "divider", borderRadius: 3 }}>
                    <Typography sx={{ m: 2 }} variant='subtitle1' fontWeight={"bold"}>OS</Typography>
                    <Divider />
                    <Box sx={{ overflow: "auto", scrollbarWidth: "thin", p: 2 }}>
                        {Object.keys(data?.staticInfo?.os).map(key => (
                            <Stack key={key} direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant='subtitle2' color={"text.secondary"}>{key}</Typography>
                                <Typography variant='subtitle1' fontFamily={"JetBrains Mono"}>{data?.staticInfo?.os?.[key] || "N/A"}</Typography>
                            </Stack>
                        ))}
                    </Box>
                    <Divider />
                    <Typography variant='subtitle2' fontWeight={"light"} color={"text.disabled"} align='center' sx={{ m: 2 }}>{data?.machineId}</Typography>
                </Box>
            </Grid>}
            {data && data?.staticInfo?.baseboard && <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box id={"baseboard"} sx={{ border: 1, borderColor: "divider", borderRadius: 3 }}>
                    <Typography sx={{ m: 2 }} variant='subtitle1' fontWeight={"bold"}>Baseboard</Typography>
                    <Divider />
                    <Box sx={{ overflow: "auto", scrollbarWidth: "thin", p: 2 }}>
                        {Object.keys(data?.staticInfo?.baseboard).map(key => (
                            <Stack key={key} direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant='subtitle2' color={"text.secondary"}>{key}</Typography>
                                <Typography variant='subtitle1' fontFamily={"JetBrains Mono"}>{data?.staticInfo?.baseboard?.[key] || "N/A"}</Typography>
                            </Stack>
                        ))}
                    </Box>
                    <Divider />
                    <Typography variant='subtitle2' fontWeight={"light"} color={"text.disabled"} align='center' sx={{ m: 2 }}>{data?.machineId}</Typography>
                </Box>
            </Grid>}
            {data && data?.staticInfo?.chassis && <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box id={"chassis"} sx={{ border: 1, borderColor: "divider", borderRadius: 3 }}>
                    <Typography sx={{ m: 2 }} variant='subtitle1' fontWeight={"bold"}>Chassis</Typography>
                    <Divider />
                    <Box sx={{ overflow: "auto", scrollbarWidth: "thin", p: 2 }}>
                        {Object.keys(data?.staticInfo?.chassis).map(key => (
                            <Stack key={key} direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant='subtitle2' color={"text.secondary"}>{key}</Typography>
                                <Typography variant='subtitle1' fontFamily={"JetBrains Mono"}>{data?.staticInfo?.chassis?.[key] || "N/A"}</Typography>
                            </Stack>
                        ))}
                    </Box>
                    <Divider />
                    <Typography variant='subtitle2' fontWeight={"light"} color={"text.disabled"} align='center' sx={{ m: 2 }}>{data?.machineId}</Typography>
                </Box>
            </Grid>}
            {data && data?.staticInfo?.bios && <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box id={"bios"} sx={{ border: 1, borderColor: "divider", borderRadius: 3 }}>
                    <Typography sx={{ m: 2 }} variant='subtitle1' fontWeight={"bold"}>Bios</Typography>
                    <Divider />
                    <Box sx={{ overflow: "auto", scrollbarWidth: "thin", p: 2 }}>
                        {Object.keys(data?.staticInfo?.bios).map(key => (
                            <Stack key={key} direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant='subtitle2' color={"text.secondary"}>{key}</Typography>
                                <Typography variant='subtitle1' fontFamily={"JetBrains Mono"}>{data?.staticInfo?.bios?.[key] || "N/A"}</Typography>
                            </Stack>
                        ))}
                    </Box>
                    <Divider />
                    <Typography variant='subtitle2' fontWeight={"light"} color={"text.disabled"} align='center' sx={{ m: 2 }}>{data?.machineId}</Typography>
                </Box>
            </Grid>}
            {data && data?.staticInfo?.versions && <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box id={"versions"} sx={{ border: 1, borderColor: "divider", borderRadius: 3 }}>
                    <Typography sx={{ m: 2 }} variant='subtitle1' fontWeight={"bold"}>App Versions</Typography>
                    <Divider />
                    <Box sx={{ overflow: "auto", scrollbarWidth: "thin", p: 2 }}>
                        {Object.keys(data?.staticInfo?.versions).map(key => (
                            <Stack key={key} direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant='subtitle2' color={"text.secondary"}>{key}</Typography>
                                <Typography variant='subtitle1' fontFamily={"JetBrains Mono"}>{data?.staticInfo?.versions?.[key] === "" ? "N/A" : "" + data?.staticInfo?.versions?.[key]}</Typography>
                            </Stack>
                        ))}
                    </Box>
                    <Divider />
                    <Typography variant='subtitle2' fontWeight={"light"} color={"text.disabled"} align='center' sx={{ m: 2 }}>{data?.machineId}</Typography>
                </Box>
            </Grid>}
        </Grid>
    )
}

const MachineDynamicInfos = ({ data, loading, error }) => {
    const [dynamicKeys, setDynamicKeys] = useState([])

    useEffect(() => {
        if (data && data?.dynamicInfo) {
            setDynamicKeys(Object.keys(data?.dynamicInfo))
        }
    }, [data])

    const [used, setUsed] = useState(false)
    const [usedMemory, setUsedMemory] = useState(true)
    const [showCPUs, setShowCPUs] = useState(false)

    const networkConnectionsColumns = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'pid', headerName: 'PID', width: 90 },
        { field: 'protocol', headerName: 'Protocol', width: 90 },
        { field: 'state', headerName: 'State', width: 120 },
        { field: 'localAddress', headerName: 'Local Addr.', width: 200 },
        { field: 'localPort', headerName: 'Local Port', width: 90 },
        { field: 'peerAddress', headerName: 'Peer Addr.', width: 200 },
        // {field: 'peerPort', headerName: 'Peer Port', width: 90 },
        // {field: 'process', headerName: 'Process', width: 90 },
    ]

    const wifiNetworksColumns = [
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'bssid', headerName: 'BSSID', width: 200 },
        { field: 'channel', headerName: 'Channel', width: 90 },
        { field: 'frequency', headerName: 'Frequency', width: 120 },
        // {field: 'mode', headerName: 'Mode', width: 90 },
        { field: 'quality', headerName: 'Quality', width: 100 },
        { field: 'signalLevel', headerName: 'Signal Level', width: 100 },
        { field: 'ssid', headerName: 'SSID', width: 200 },
        { field: 'security', headerName: 'Security', width: 200 },
        { field: 'wpaFlags', headerName: 'wpa flags', width: 150 },
        { field: 'rsnFlags', headerName: 'rsn flags', width: 150 },
    ]

    return (
        <Grid id={data?.id} container gap={2} justifyContent={"center"} alignItems={"flex-start"} sx={{ wordBreak: "break-all" }}>
            {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                <Card sx={{ borderRadius: 3, border: 1, borderColor: "divider", maxHeight: 500 }}>
                    <CardMedia component={"div"} id={"machineLocations"} style={{ width: '100%', height: 500 }} />
                </Card>
            </Grid> */}
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container gap={1} justifyContent={"flex-start"} alignItems={"center"}>
                    {dynamicKeys && dynamicKeys.length > 0 && dynamicKeys.map((key, index) => (
                        <Grid item key={key}>
                            <Chip size='medium' label={key} variant='outlined' sx={{ p: 2 }} onClick={() => { document.getElementById(key)?.scrollIntoView({ behavior: "smooth" }) }} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container gap={2} justifyContent={"flex-start"} alignItems={"flex-start"}>
                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant='subtitle1' color={"text.secondary"} fontWeight={"bold"}>Memory & CPU</Typography>
                    </Grid> */}
                    {data?.dynamicInfo?.mem?.total && data?.dynamicInfo?.mem?.free && data?.dynamicInfo?.mem?.used && data?.dynamicInfo?.mem?.active && data?.dynamicInfo?.mem?.available &&
                        <Grid item xs={12} sm={12} md={5} lg={5} key={data?.dynamicInfo?.mem?.fs}>
                            <Tooltip title={`${((data?.dynamicInfo?.mem?.used / data?.dynamicInfo?.mem?.total) * 100)?.toPrecision(4)} %`} >
                                <Button onClick={() => setUsedMemory(ps => !ps)} startIcon={<MemoryIcon />} variant="outlined" sx={{ py: 2, px: 2, justifyContent: "left", borderRadius: 3 }} color={(data?.dynamicInfo?.mem?.used / data?.dynamicInfo?.mem?.total) * 100 < 50 ? "success" : (data?.dynamicInfo?.mem?.used / data?.dynamicInfo?.mem?.total) * 100 < 80 ? "warning" : "error"} fullWidth>
                                    <Stack>
                                        <Typography variant="subtitle1" fontWeight={"light"}>{`Memory ${usedMemory ? formatBytes(data?.dynamicInfo?.mem?.used) : formatBytes(data?.dynamicInfo?.mem?.available) + " free "} of ${formatBytes(data?.dynamicInfo?.mem?.total)} ${used ? "used" : ""}`}</Typography>
                                        <LinearProgress color={(data?.dynamicInfo?.mem?.used / data?.dynamicInfo?.mem?.total) * 100 < 50 ? "success" : (data?.dynamicInfo?.mem?.used / data?.dynamicInfo?.mem?.total) * 100 < 80 ? "warning" : "error"} variant="determinate" value={(data?.dynamicInfo?.mem?.used / data?.dynamicInfo?.mem?.total) * 100} sx={{ borderRadius: 2 }} />
                                    </Stack>
                                </Button>
                            </Tooltip>
                        </Grid>
                    }
                    {data?.dynamicInfo?.currentLoad &&
                        <Grid item xs={12} sm={12} md={5} lg={5} key={data?.dynamicInfo?.currentLoad?.fs}>
                            <Tooltip title={`${(data?.dynamicInfo?.currentLoad?.currentLoad)?.toPrecision(4)} %`} >
                                <Button onClick={() => setShowCPUs(ps => !ps)} startIcon={<MemoryIcon />} endIcon={showCPUs ? <ExpandLessIcon /> : <ExpandMoreIcon />} variant="outlined" sx={{ py: 2, px: 2, justifyContent: "space-between", borderRadius: 3 }} color={(data?.dynamicInfo?.currentLoad.currentLoad) < 50 ? "success" : (data?.dynamicInfo?.currentLoad.currentLoad) < 80 ? "warning" : "error"} fullWidth>
                                    <Stack>
                                        <Typography variant="subtitle1" fontWeight={"light"}>{`CPU ${(data?.dynamicInfo?.currentLoad?.currentLoad)?.toPrecision(4)} %`}</Typography>
                                        <LinearProgress color={(data?.dynamicInfo?.currentLoad.currentLoad) < 50 ? "success" : (data?.dynamicInfo?.currentLoad.currentLoad) < 80 ? "warning" : "error"} variant="determinate" value={(data?.dynamicInfo?.currentLoad.currentLoad)} sx={{ borderRadius: 2 }} />
                                    </Stack>
                                </Button>
                            </Tooltip>
                        </Grid>
                    }
                    {showCPUs && data?.dynamicInfo?.currentLoad?.cpus.map((cpu, index) => (
                        <Grid item xs={12} sm={12} md={5} lg={5} key={index}>
                            <Tooltip title={`${(cpu?.load)?.toPrecision(4)} %`} >
                                <Button onClick={() => setShowCPUs(ps => !ps)} startIcon={<MemoryIcon />} variant="outlined" sx={{ py: 2, px: 2, justifyContent: "left", borderRadius: 3 }} color={(cpu?.load) < 50 ? "success" : (cpu?.load) < 80 ? "warning" : "error"} fullWidth>
                                    <Stack>
                                        <Typography variant="subtitle1" fontWeight={"light"}>{`CPU ${index + 1} • ${(cpu?.load)?.toPrecision(4)} %`}</Typography>
                                        <LinearProgress color={(cpu.load) < 50 ? "success" : (cpu.load) < 80 ? "warning" : "error"} variant="determinate" value={(cpu.load)} sx={{ borderRadius: 2 }} />
                                    </Stack>
                                </Button>
                            </Tooltip>
                        </Grid>
                    ))}
                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant='subtitle1' color={"text.secondary"} fontWeight={"bold"}>Disk info</Typography>
                    </Grid> */}
                    {data?.dynamicInfo?.fsSize && data?.dynamicInfo?.fsSize
                        .filter(x => x?.fs && x?.type && x.size && x?.used && x?.use && x?.mount && x)
                        .map((x) => (
                            <Grid item xs={12} sm={12} md={5} lg={5} key={x.fs}>
                                <Tooltip title={`${x.use}%`} >
                                    <Button onClick={() => setUsed(ps => !ps)} startIcon={<StorageIcon />} variant="outlined" sx={{ py: 2, px: 2, justifyContent: "left", borderRadius: 3 }} color={x.use < 50 ? "success" : x.use < 80 ? "warning" : "error"} fullWidth>
                                        <Stack>
                                            <Typography variant="subtitle1" fontWeight={"light"}>{`${x.fs}/ ${used ? formatBytes(x.used) : formatBytes(x.available) + " free "} of ${formatBytes(x.size)} ${used ? "used" : ""}`}</Typography>
                                            <LinearProgress color={x.use < 50 ? "success" : x.use < 80 ? "warning" : "error"} variant="determinate" value={x.use} sx={{ borderRadius: 2 }} />
                                        </Stack>
                                    </Button>
                                </Tooltip>
                            </Grid>
                        ))}
                </Grid>
            </Grid>
            {data?.dynamicInfo?.networkConnections && data?.dynamicInfo?.networkConnections.length > 0 && <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant='subtitle1' color={"text.secondary"} fontWeight={"bold"} id={"networkConnections"}>Network Connections</Typography>
            </Grid>}
            {data?.dynamicInfo?.networkConnections && data?.dynamicInfo?.networkConnections.length > 0 && <Grid item xs={12} sm={12} md={12} lg={12}>
                <DataGrid
                    rows={data?.dynamicInfo?.networkConnections.filter(x => x?.localAddress && x?.localPort && x?.peerAddress && x?.peerPort && x?.protocol && x?.state).map((x, index) => ({ id: index + 1, ...x }))}
                    columns={networkConnectionsColumns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                    // checkboxSelection
                    disableRowSelectionOnClick
                    sx={{ borderRadius: 3 }}
                />
            </Grid>}
            {data?.dynamicInfo?.wifiNetworks && data?.dynamicInfo?.wifiNetworks.length > 0 && <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant='subtitle1' color={"text.secondary"} fontWeight={"bold"} id={"wifiNetworks"}>WiFi Networks</Typography>
            </Grid>}
            <Grid item xs={12} sm={12} md={12} lg={12}>
                {data?.dynamicInfo?.wifiNetworks && data?.dynamicInfo?.wifiNetworks.length > 0 && <DataGrid
                    rows={data?.dynamicInfo?.wifiNetworks.filter(x => x?.bssid && x?.channel && x?.frequency && x?.quality && x?.signalLevel && x?.ssid).map((x, index) => ({ id: index + 1, ...x, security: x?.security.join(", "), wpaFlags: x?.wpaFlags.join(", "), rsnFlags: x?.rsnFlags.join(", ") }))}
                    columns={wifiNetworksColumns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                    // checkboxSelection
                    disableRowSelectionOnClick
                    sx={{ borderRadius: 3 }}
                />}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                {data && data?.dynamicInfo?.time && <Box id={"time"} sx={{ border: 1, borderColor: "divider", borderRadius: 3 }}>
                    <Typography sx={{ m: 2 }} variant='subtitle1' fontWeight={"bold"}>Time</Typography>
                    <Divider />
                    <Box sx={{ overflow: "auto", scrollbarWidth: "thin", p: 2 }}>
                        {Object.keys(data?.dynamicInfo?.time).filter(key => !["current"].includes(key) && key).map(key => (
                            <Stack key={key} direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant='subtitle2' color={"text.secondary"}>{key}</Typography>
                                {key !== "uptime" && <Typography variant='subtitle1' fontFamily={"JetBrains Mono"}>{data?.dynamicInfo?.time?.[key] === "" ? "N/A" : "" + data?.dynamicInfo?.time?.[key]}</Typography>}
                                {key === "uptime" && <Typography variant='subtitle1' fontFamily={"JetBrains Mono"}>{data?.dynamicInfo?.time?.[key] === "" ? "N/A" : "" + formatDuration(data?.dynamicInfo?.time?.[key])}</Typography>}
                            </Stack>
                        ))}
                    </Box>
                    <Divider />
                    <Typography variant='subtitle2' fontWeight={"light"} color={"text.disabled"} align='center' sx={{ m: 2 }}>{data?.machineId}</Typography>
                </Box>}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                {data && data?.dynamicInfo?.battery && <Box id={"battery"} sx={{ border: 1, borderColor: "divider", borderRadius: 3 }}>
                    <Typography sx={{ m: 2 }} variant='subtitle1' fontWeight={"bold"}>Battery</Typography>
                    <Divider />
                    <Box sx={{ overflow: "auto", scrollbarWidth: "thin", p: 2 }}>
                        {Object.keys(data?.dynamicInfo?.battery).map(key => (
                            <Stack key={key} direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography variant='subtitle2' color={"text.secondary"}>{key}</Typography>
                                <Typography variant='subtitle1' fontFamily={"JetBrains Mono"}>{data?.dynamicInfo?.battery?.[key] === "" ? "N/A" : "" + data?.dynamicInfo?.battery?.[key]}</Typography>
                            </Stack>
                        ))}
                    </Box>
                    <Divider />
                    <Typography variant='subtitle2' fontWeight={"light"} color={"text.disabled"} align='center' sx={{ m: 2 }}>{data?.machineId}</Typography>
                </Box>}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                {data && data?.dynamicInfo?.processes && <Box id={"processes"} sx={{ border: 1, borderColor: "divider", borderRadius: 3 }}>
                    <Typography sx={{ m: 2 }} variant='subtitle1' fontWeight={"bold"}>Processes</Typography>
                    <Divider />
                    <Box sx={{ overflow: "auto", scrollbarWidth: "thin", p: 2 }}>
                        {data?.dynamicInfo?.processes?.list.map((process, index) => (<Stack key={index}>
                            <Divider>
                                <Chip label={`process ${index + 1} • PID: ${process?.pid}`} size="small" />
                            </Divider>
                            {Object.keys(process).map(key => (
                                <Stack key={key} direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                                    <Typography variant='subtitle2' color={"text.secondary"}>{key}</Typography>
                                    {!["memVsz", "memRss", "cpu", "cpus", "cpuu", "mem", "command"].includes(key) && <Typography variant='subtitle1' fontFamily={"JetBrains Mono"}>{process?.[key]}</Typography>}
                                    {["command"].includes(key) && <Typography variant='caption' fontFamily={"JetBrains Mono"}>{process?.[key] || "N/A"}</Typography>}
                                    {["cpu", "cpus", "cpuu", "mem"].includes(key) && <Typography variant='subtitle1' fontFamily={"JetBrains Mono"}>{process?.[key].toPrecision(2) + " %" || "N/A"}</Typography>}
                                    {["memVsz", "memRss"].includes(key) && <Typography variant='subtitle1' fontFamily={"JetBrains Mono"}>{formatBytes(process?.[key], 2) || "N/A"}</Typography>}
                                </Stack>
                            ))}
                        </Stack>))}
                    </Box>
                    <Divider />
                    <Typography variant='subtitle2' fontWeight={"light"} color={"text.disabled"} align='center' sx={{ m: 2 }}>{data?.machineId}</Typography>
                </Box>}
            </Grid>
        </Grid>
    )
}