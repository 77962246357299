import React, { useState, useEffect, useRef } from 'react';
// import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/WebGLTile';
import OSM from 'ol/source/OSM';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Overlay } from 'ol';
import { fromLonLat, toLonLat } from 'ol/proj.js';
import { toStringHDMS } from 'ol/coordinate.js';
import Draw from 'ol/interaction/Draw.js';
import { FullScreen, defaults as defaultControls } from 'ol/control.js';
import Graticule from 'ol/layer/Graticule.js';
import Link from 'ol/interaction/Link.js';
import { Icon, Style, Text, Fill, Stroke } from 'ol/style';

import { Typography, Card, CardMedia, Divider, Stack, ListItem, ListItemText, Avatar, ListItemAvatar, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

import ShowAddress from './ShowAddress';

import { socket } from '../utilities/socket';
import { format, formatDistanceToNow } from 'date-fns';

import { useTheme } from '@mui/material/styles';
import { AppContextProvider } from '../AppContext';
import CustomThemeProvider from '../CustomTheme';

import DevicesIcon from '@mui/icons-material/Devices';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { BsWindows, BsApple } from 'react-icons/bs'
import { FcLinux } from "react-icons/fc";
import { ImAndroid } from "react-icons/im";

const MOBILE_OS_LIST = ['android', 'ios']
const PC_OS_LIST = ['windows', 'linux', 'osx']

const LiveLocationMap = ({ id = "LiveMap", maxHeight, startLocation = [78.9629, 20.5937], matchDid = "" }) => {
    const theme = useTheme()
    const [markerData, setMarkerData] = useState([]);

    // Ref for the vector layer that contains the markers 
    const vectorLayerRef = useRef();

    let draw; // global so we can remove it later 
    function addInteraction(map, drawSource, type = "Circle") {
        const value = type;
        if (value !== 'None') {
            draw = new Draw({
                source: drawSource,
                type: type,
                freehand: true,
            });
            map.addInteraction(draw);
        }
    }

    useEffect(() => {
        if (id) {
            console.log(`🗺️ ${id} Map Draw`)
            // Create a vector layer for the markers 
            const vectorSource = new VectorSource();
            const vectorLayer = new VectorLayer({
                source: vectorSource,
            });
            vectorLayerRef.current = vectorLayer;

            const drawSource = new VectorSource({ wrapX: false });
            const drawVector = new VectorLayer({
                source: drawSource,
            });

            const graticuleLines = new Graticule({
                // the style to use for the lines, optional. 
                strokeStyle: new Stroke({
                    color: '#bdbdbd',
                    width: 1,
                    lineDash: [0.5, 4],
                }),
                showLabels: false,
                wrapX: false,
            })

            // Create a map with the OpenLayers library 
            const map = new Map({
                controls: defaultControls().extend([new FullScreen()]),
                target: id,
                layers: [
                    new TileLayer({
                        source: new OSM(),
                    }),
                    vectorLayer,
                    drawVector,
                    // graticuleLines,
                ],
                view: new View({
                    center: fromLonLat([78.9629, 20.5937]), // [lat, lon]
                    zoom: 3,
                }),
            });

            // map.addInteraction(new Link());

            // dark mode map 
            // map.on('postcompose', function (e) {
            //     document.querySelector('canvas').style.filter = ({ dark: "grayscale(80%) invert(100%) ", light: "" }[theme.palette.mode]);
            // });

            // addInteraction(map, drawSource, ["LineString", "Polygon", "Circle", "None"][3])

            // Add a mouseover (pointermove) event listener to the map 
            map.on('click' || 'pointermove', (event) => {
                const feature = map.forEachFeatureAtPixel(event.pixel, (feature) => feature);
                if (feature && feature.get('overlay')) {
                    // console.log(feature?.id_) 
                    const overlay = feature.get('overlay');
                    overlay.setPosition(event.coordinate);
                    map.addOverlay(overlay)
                } else {
                    const overlays = map.getOverlays().getArray();
                    overlays.forEach((overlay) => { overlay.setPosition(undefined); map.removeOverlay(overlay) });
                }
            });

            // map.on("dblclick", (event) => { 
            //     map.removeInteraction(draw) 
            // }) 

            // map.on('click', function (evt) {
            //     const coordinate = evt.coordinate;
            //     const hdms = toStringHDMS(toLonLat(coordinate));
            //     console.log(hdms)
            //     console.table(coordinate);

            // });

            return () => {
                // Clean up the map when the component unmounts 
                map.setTarget(null);
            };
        }
    }, [id]);

    useEffect(() => {
        if (id) {
            socket.connect()

            socket.on("connect", () => {
                console.log("🟢 socket connected! %s", socket?.id)
            });

            socket.on("liveLocations", (data) => {
                // console.log("liveLocations:", data)
                let liveLocations = Object.keys(data)
                    // .filter(key => data[key]?.timestamp && (new Date().getTime() - new Date(data[key]?.timestamp).getTime()) < 5 * 60 * 1000 && key) // 5 min filter
                    .filter(key => data[key]?.did && data[key]?.latitude && data[key]?.longitude && key)
                    .map(key => ({
                        id: data[key]?.did,
                        lat: data[key]?.latitude,
                        lng: data[key]?.longitude,
                        timestamp: data[key]?.timestamp,
                        ...data[key]
                    }))
                // console.log("liveLocations:", liveLocations)
                setMarkerData(liveLocations)
            })

            // socket.on("nicknames", (nicknames) => {
            //     setNicknames(nicknames)
            // })
        }

        return () => {
            socket.off()
            console.log("🔴 socket disconnected!")
            socket.disconnect()
            socket.off("liveLocations");
        };
    }, [id])

    useEffect(() => {
        // Update the vector layer with the new marker data 
        if (vectorLayerRef.current && markerData.length) {
            const markers = markerData
                // .filter(x => (x?.id === matchDid || x?.id === appState?.did) && x)
                .map((marker) => {
                    const { id, lat, lng, deviceInfo } = marker;
                    const markerFeature = new Feature({
                        geometry: new Point(fromLonLat([lng, lat])),
                    });

                    const { operatingSystem } = deviceInfo

                    markerFeature.setStyle(new Style({
                        image: new Icon({
                            crossOrigin: 'anonymous',
                            src: MOBILE_OS_LIST.includes(operatingSystem) ? 'mobile-phone.svg' : PC_OS_LIST.includes(operatingSystem) ? 'laptop-computer.svg' : 'pos_anonymous.svg',
                            scale: 0.025,
                        })
                    }))

                    // Create an overlay for the tooltip 
                    const overlay = new Overlay({
                        element: document.createElement('div'),
                        offset: [5, 0],
                        positioning: 'bottom-left',
                    });
                    overlay.getElement().textContent = id;
                    overlay.getElement().innerHTML = "";

                    // ReactDOM.render(tooltipContent, overlay.getElement());
                    ReactDOM.createRoot(overlay.getElement()).render(<TooltipContent id={id} deviceInfo={deviceInfo} marker={marker} />);
                    markerFeature.set('overlay', overlay);

                    markerFeature.setId(id);
                    return markerFeature;
                });

            const vectorSource = vectorLayerRef.current.getSource();
            vectorSource.clear();
            vectorSource.addFeatures(markers);
        }
    }, [markerData, matchDid]);

    return (
        <CardMedia component={"div"} id={id} style={{ width: '100%', height: maxHeight ? maxHeight : `${window.innerHeight}px` }} />
    );
};

const TooltipContent = ({ id, deviceInfo, marker }) => {

    return (
        <AppContextProvider >
            <CustomThemeProvider>
                <Card id={id} variant='outlined' sx={{ borderRadius: 3, maxWidth: 500, wordBreak: "break-all" }}>
                    <ListItem variant="outlined" key={id}>
                        <ListItemAvatar>
                            <Avatar src={""} variant="rounded" sx={{ background: "transparent" }}>
                                <DevicesIcon color={"secondary"} />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary={id} />
                    </ListItem>
                    <Divider />
                    {deviceInfo &&
                        <Accordion sx={{ boxShadow: 0 }}>
                            <AccordionSummary
                                expandIcon={<ArrowDropDownIcon />}
                            >
                                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                    {deviceInfo?.operatingSystem && deviceInfo?.operatingSystem + "".toLowerCase() === 'windows' && <BsWindows size={"1rem"} color="#0078D6" />}
                                    {deviceInfo?.operatingSystem && deviceInfo?.operatingSystem + "".toLowerCase() === 'android' && <ImAndroid size={"1rem"} color='#3DDC84' />}
                                    {deviceInfo?.operatingSystem && deviceInfo?.operatingSystem + "".toLowerCase() === 'linux' && <FcLinux size={"1rem"} color='#3DDC84' />}
                                    {deviceInfo?.operatingSystem && ["macos", "ios", "ipados", "visionos"].includes(deviceInfo?.operatingSystem + "".toLowerCase()) && <BsApple size={"1rem"} color="#6C6C6C" />}
                                    <Typography variant='subtitle2'>Device Info {deviceInfo?.operatingSystem && `• ${deviceInfo?.operatingSystem}`}</Typography>
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack>
                                    {Object.keys(deviceInfo).map(key => (
                                        <Stack direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography variant='caption' color={"text.secondary"}>{key}</Typography>
                                            <Typography variant='subtitle2'>{"" + deviceInfo?.[key]}</Typography>
                                        </Stack>
                                    ))}
                                    <Stack justifyContent={"center"} alignItems={"center"} sx={{ mt: 1 }}>
                                        <ShowAddress location={{ latitude: marker?.lat, longitude: marker?.lng }} tiny={false} color={"secondary"} />
                                    </Stack>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>}
                    {deviceInfo && Object.keys(deviceInfo).length > 0 && <Divider />}
                    <Stack sx={{ p: 1 }}>
                        <Typography align='center' variant="subtitle2" fontWeight={"light"} color={"text.secondary"}>Latitude: {marker?.lat}, Longitude: {marker?.lng}</Typography>
                        {marker?.timestamp && <Typography align="center" color={"text.secondary"} variant="subtitle2" fontWeight={"light"}>Updated at: {marker?.timestamp && format(new Date(marker?.timestamp), "Ppp")} {`${marker?.timestamp && "(" + formatDistanceToNow(new Date(marker?.timestamp)) + " ago)"}`}</Typography>}
                    </Stack>
                </Card>
            </CustomThemeProvider>
        </AppContextProvider>
    )
}

export default LiveLocationMap;
