import { useState, useEffect } from "react";
import { Box, Stack, ToggleButton, ToggleButtonGroup, Chip } from '@mui/material';
import { pb } from "../utilities/pocketbase";

import LiveLocationMap from "../components/LiveLocationMap";
import DeviceLocationMap from "../components/DeviceLocationMap";
import MachineLocationMap from "../components/MachineLocationMap";

export default function Dashboard() {
    const [mapOf, setMapOf] = useState('liveLocations');
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }
        window.addEventListener('resize', handleResize)

        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    })

    const handleMapOf = (event, newMapOf) => {
        if (newMapOf !== null) {
            setMapOf(newMapOf);
        }
    };

    return (
        <>
            {pb?.authStore?.model?.collectionName === 'users' && <Box sx={{ position: "relative", p: 0, height: `${dimensions.height - 65}` }}>
                {mapOf === "liveLocations" && <LiveLocationMap maxHeight={dimensions.height - 65} />}
                {mapOf === "devices" && <DeviceLocationMap maxHeight={dimensions.height - 65} />}
                {mapOf === "machines" && <MachineLocationMap maxHeight={dimensions.height - 65} />}
                <Box sx={{ position: "absolute", bottom: 20, right: 0, p: 1 }}>
                    <Stack direction={"row"} spacing={1} justifyContent={"flex-end"} alignItems={"center"}>
                        <ToggleButtonGroup
                            value={mapOf}
                            exclusive
                            onChange={handleMapOf}
                            color="secondary"
                            size="small"
                        >
                            <Stack spacing={.5} >
                                <Chip variant="outlined" sx={{ textTransform: "none", color: "#282C34", fontWeight: "bold", border: 2 }} label="Live Locations" component={ToggleButton} value="liveLocations" />
                                <Chip variant="outlined" sx={{ textTransform: "none", color: "#282C34", fontWeight: "bold", border: 2 }} label="Devices" component={ToggleButton} value="devices" />
                                <Chip variant="outlined" sx={{ textTransform: "none", color: "#282C34", fontWeight: "bold", border: 2 }} label="Machines" component={ToggleButton} value="machines" />
                            </Stack>
                        </ToggleButtonGroup>
                    </Stack>
                </Box>
            </Box>}
        </>
    )
}