import * as React from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import { AuthRequiredComponent, AuthRequiredRoutes } from "./components/Auth";

import { PrimeReactProvider } from 'primereact/api';

import { AppContextProvider } from './AppContext';
import CustomThemeProvider, { baseTheme } from './CustomTheme';

import './App.css';
// import "primereact/resources/themes/lara-dark-cyan/theme.css";

import Login from './components/Login';
import NavBar from './components/NavBar';
import PageNotFound from './pages/PageNotFound';
import Dashboard from './pages/Dashboard';
import Machines from './components/Machines';
import Devices from './components/Devices';

function App() {

    return (
        <AppContextProvider>
            <CustomThemeProvider>
                <PrimeReactProvider>
                    <Router basename={process.env.PUBLIC_URL}>
                        <AuthRequiredComponent
                            auth={<NavBar />}
                            noAuth={<NavBar type='nomenu' />}
                        />
                        <Box sx={{ minHeight: "calc(100vh - 180px)", flexGrow: 1 }} className='content-wrapper'>
                            <React.Suspense fallback={<SuspenseFallback />}>
                                <MainRoutes />
                            </React.Suspense>
                        </Box>

                    </Router>
                </PrimeReactProvider>
            </CustomThemeProvider>
        </AppContextProvider>
    );
}

function MainRoutes() {
    return (
        <Routes>
            <Route
                exact
                path="/"
                element={
                    <AuthRequiredComponent
                        auth={<Navigate to='/dashboard' />}
                        noAuth={<Login />}
                    />
                }
            />

            {/* Logged in users */}
            <Route element={<AuthRequiredRoutes />}>
                <Route path="/dashboard" element={<Dashboard />} exact />
                <Route path="/machines" element={<Machines />} exact />
                <Route path="/devices" element={<Devices />} exact />
            </Route>

            <Route path="/login" element={<Login />} />

            {/* 404 page */}
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    )
}


const SuspenseFallback = () => {

    return (
        <Stack spacing={2} sx={{ p: 4 }} justifyContent={"center"} alignItems={"center"}>
            <CircularProgress sx={{ color: "text.disabled" }} />
            <Typography variant='subtitle1' fontWeight={"light"}> Loading...</Typography>
        </Stack>
    )
}

export default App;