import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Grid, Typography, TextField, Container, CardMedia, Box } from '@mui/material';
import { Button, Alert } from '@mui/material';
import { Card, CardContent, ToggleButtonGroup, ToggleButton } from '@mui/material';

import { pb } from '../utilities/pocketbase';

import { ReactComponent as KunctIcon } from '../knuctIcon.svg'

export default function Login() {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")
    const [sendRequest, setSendRequest] = useState(false)

    const navigate = useNavigate();

    const handleUsername = (e) => {
        setUsername(e.target.value)
    }

    const handlePassword = (e) => {
        setPassword(e.target.value)
    }

    useEffect(() => {
        window.document.title = 'Login'
        return () => { window.document.title = process.env.REACT_APP_NAME }
    }, [])

    useEffect(() => {
        if (sendRequest && username && password) {
            setError("")
            pb.collection(`users`).authWithPassword(username, password).then(record => {
                // console.log("login record:", record)
                // console.log(pb.authStore.isValid);
                // console.log(pb.authStore.token);
                console.log(pb.authStore.model.id);
                navigate('/')
            }).catch(error => {
                console.log("login error:", error)
                setError(error?.message)
            }).finally(() => setSendRequest(false))
        }
    }, [sendRequest, username, password, navigate])

    return (
        <Container>
            <Grid container justifyContent={"center"} alignItems={"center"}>
                <Grid item xs={12} sm={12} md={6} lg={4} justifyContent={"center"} alignItems={"center"}>
                    <Stack spacing={3} justifyContent={"center"} alignItems={"center"} sx={{ p: 2, my: 4 }}>
                        <CardMedia sx={{ mr: 1 }}>
                            <KunctIcon style={{ width: '5em', height: '5em', verticalAlign: 'center' }} />
                        </CardMedia>
                        <Typography align='center' variant='subtitle1' fontFamily={"JetBrains Mono"}>KNUCT DISCOVER</Typography>
                        <Typography align='center' variant='h5' fontFamily={"JetBrains Mono"}>LOGIN</Typography>
                        <TextField fullWidth value={username} onChange={handleUsername} label={"username"} onKeyDown={(e) => { e.key === "Enter" && setSendRequest(true) }} />
                        <TextField fullWidth value={password} onChange={handlePassword} label={"password"} type='password' onKeyDown={(e) => { e.key === "Enter" && setSendRequest(true) }} />
                        <Button onClick={() => setSendRequest(true)} variant="contained" size="large" className='shadow' sx={{ py: 2, textTransform: "none" }} fullWidth>Login</Button>
                        {error && <Alert severity='error' sx={{ justifyContent: "center", alignItems: "center" }}>{error}</Alert>}
                    </Stack>
                </Grid>
            </Grid>
        </Container>
    )
}